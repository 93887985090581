import React, { useEffect, useState } from "react";
import { MButton, MContainer } from "../../element/Elemens";
import "../../assets/scss/_login.scss";
import { authLogin, authPassword } from "../../redux/actions/userActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import image from "../../assets/images/rabota.png";
import Loading from "../../component/Loading";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [{ phone_number, code }, setUser] = useState({
    phone_number: "",
    code: ""
  });

  const { loginStep, token, loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (token !== null) {
      navigate("/profile/profile-info");
      window.location.reload();
    }
  }, [token]);

  const handleLogin = () => {
    dispatch(authLogin({ phone_number, code }));
  };

  const handleUsername = (value) => {
    setUser((prevState) => ({ ...prevState, phone_number: value }));
  };
  const handlePassword = (e) =>
    setUser((prevState) => ({ ...prevState, code: e.target.value }));

  const handleKeyLogin = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleKeyUserName = (e) => {
    if (e.key === "Enter") {
      dispatch(authPassword(phone_number));
    }
  };

  return (
    <>
      <MContainer>
        <div className="login__page">
          <div className="login__page__row">
            <div className="login__page__box__image">
              <div className="login__page__box__image__box">
                <img src={image} alt="Roboto image" />
              </div>
            </div>
            <div className="login__page__box">
              {!loginStep ? (
                <div>
                  _____________<h2>Tizimga kirish</h2>
                  <div className="login__page__phone__label">
                    Telefon raqamingizni kiriting
                  </div>
                  <div className="login__page__box__form__group">
                    <PhoneInput
                      placeholder="Enter phone number"
                      country={"uz"}
                      onChange={handleUsername}
                      value={phone_number}
                      onKeyPress={handleKeyUserName}
                      defaultCountry="uz"
                      defaultMask="(..) ...-..-.."
                      alwaysDefaultMask={true}
                    />
                  </div>
                  <div className="login__page__box__form__group">
                    <MButton
                      onClick={() => dispatch(authPassword({ phone_number }))}
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        <div className="button__box__none__loading"></div>
                      )}
                      Davom ettirish
                    </MButton>
                  </div>
                </div>
              ) : (
                <div>
                  _____________<h2>Sms kodni kiriting</h2>
                  <div className="login__page__phone__label">
                    Telefon raqamingizni kiriting
                  </div>
                  <div className="login__page__box__form__group">
                    <PhoneInput
                      placeholder="Enter phone number"
                      country={"uz"}
                      value={phone_number}
                      disabled={true}
                      defaultCountry="uz"
                      defaultMask="(..) ...-..-.."
                      alwaysDefaultMask={true}
                    />
                  </div>
                  <div className="login__page__box__form__group">
                    <input
                      className="login__page__box__input"
                      placeholder="Sms-kodi"
                      onChange={handlePassword}
                      value={code}
                      onKeyPress={handleKeyLogin}
                    />
                  </div>
                  <div className="login__page__box__form__group">
                    <MButton
                      onClick={() =>
                        dispatch(
                          authLogin({
                            phone_number,
                            code
                          })
                        )
                      }
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        <div className="button__box__none__loading"></div>
                      )}
                      Davom ettirish
                    </MButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MContainer>
    </>
  );
};

export default Login;
