const INITIAL_STATE = {
  list: [],
  loading: false,
  message: null,
  sidebarShow: "responsive",
  data: {},
  meta: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    /* other */
    case "create_order_start":
      return { ...state, loading: true, message: "" };
    case "create_order_error":
      return { ...state, message: payload, loading: false };
    case "create_order_success":
      return {
        ...state,
        message: payload.message,
        loading: false
      };

    /* other */
    case "update_order_pending_start":
      return { ...state, loading: true, message: "" };
    case "update_order_pending_error":
      return { ...state, message: payload, loading: false };
    case "update_order_pending_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        listPending: newListPending,
        listNew: [...state.listNew, payload.data]
      };

    /* other */
    case "fetch_orders_stream_state_start":
      return { ...state, loading: true, message: "" };
    case "fetch_orders_stream_state_error":
      return { ...state, message: payload, loading: false };
    case "fetch_orders_stream_state_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload
      };

    /* other */
    case "fetch_orders_agent_start":
      return { ...state, loading: true, message: "" };
    case "fetch_orders_agent_error":
      return { ...state, message: payload, loading: false };
    case "fetch_orders_agent_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
        meta: payload.meta
      };

    /* other */
    case "update_order_new_start":
      return { ...state, loading: true, message: "" };
    case "update_order_new_error":
      return { ...state, message: payload, loading: false };
    case "update_order_new_success":
      const newListPending = state.list.filter(
        (item) => item.id !== payload.data.id
      );

      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newListPending
      };

    default:
      return state;
  }
};
