import requests from "../../helpers/requests";

// All orders
export const fetchProductWiew = () => (dispatch) => {
  dispatch({ type: "fetch_products_wiew_start" });

  requests
    .fetchProductWiew()
    .then(({ data }) => {
      dispatch({
        type: "fetch_products_wiew_success",
        payload: data,
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "fetch_products_wiew_error",
        payload: message,
      });
    });
};

// All orders
export const fetchBestSellingProduct = () => (dispatch) => {
  dispatch({ type: "fetch_products_best_selling_start" });

  requests
    .fetchBestSellingProduct()
    .then(({ data }) => {
      dispatch({
        type: "fetch_products_best_selling_success",
        payload: data,
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "fetch_products_best_selling_error",
        payload: message,
      });
    });
};
