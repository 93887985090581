import React from "react";
import { Skeleton } from "./Skeleton";
import "../../../assets/scss/_profile.scss";

export const ProfileLoadingGlavni = () => {
  return (
    <div className="grid__cart">
      {[0, 1, 2, 3, 4, 5].map((item) => {
        return (
          <div className="profile__cart" key={item}>
            <div className="profile__cart__body__row">
              <div className="profile__cart__image__ramci_parent">
                <Skeleton type="profile__cart__image__ramci" />
                <div className="shimmer-wrapper">
                  <div className="shimmer"></div>
                </div>
              </div>

              <div className="product__order__form__title__parent">
                <Skeleton type="product__order__form__title" />
                <div className="shimmer-wrapper">
                  <div className="shimmer"></div>
                </div>
              </div>
            </div>
            <div className="product__order__form__title__parent">
              <Skeleton type="product__order__form__title" />
              <div className="shimmer-wrapper">
                <div className="shimmer"></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
