import requests from "../../helpers/requests";
import { toast } from "react-toastify";

export const authLogin = (params) => (dispatch) => {
  dispatch({ type: "auth_login_start", payload: params });

  requests
    .authLogin(params)
    .then(({ data }) => {
      dispatch({ type: "auth_login_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Sms kodini tug'ri kiriting";
      toast.error(message);

      dispatch({ type: "auth_login_error", payload: message });
    });
};

// auth
export const authPassword = (username) => (dispatch) => {
  dispatch({ type: "auth_password_start", payload: username });

  requests
    .authPassword(username)
    .then(({ data }) => {
      dispatch({ type: "auth_password_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Adminga murojat qiling urinishlar soni tugadi!";
      dispatch({ type: "auth_password_error", payload: message });
      toast.error(message);
    });
};

// profile informations
export const profileInfo = () => (dispatch) => {
  dispatch({ type: "profile_info_start" });

  requests
    .getMe()
    .then(({ data }) => {
      dispatch({ type: "profile_info_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";

      dispatch({ type: "profile_info_error", payload: message });
    });
};

// create stream
export const createStream = (params) => (dispatch) => {
  dispatch({ type: "create_stream_start", payload: params });

  requests
    .createStream(params)
    .then(({ data }) => {
      dispatch({ type: "create_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";

      dispatch({ type: "create_stream_error", payload: message });
    });
};

// create stream
export const updateProfile = (params) => (dispatch) => {
  dispatch({ type: "update_profile_start", payload: params });

  requests
    .updateProfile(params)
    .then(({ data }) => {
      dispatch({ type: "update_profile_success", payload: data });
      toast.success("Ma'lumotingiz saqlandi");
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";
      toast.error("Foydalanuvchi profilini yuklashda xatolik bor");
      dispatch({ type: "update_profile_error", payload: message });
    });
};

// create stream
export const fetchMyTeam = () => (dispatch) => {
  dispatch({ type: "fetch_my_team_start" });

  requests
    .fetchMyTeam()
    .then(({ data }) => {
      dispatch({ type: "fetch_my_team_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";
      toast.error("Something is wrong with my team");
      dispatch({ type: "fetch_my_team_error", payload: message });
    });
};

// create stream
export const fetchContestState = () => (dispatch) => {
  dispatch({ type: "fetch_contest_state_start" });

  requests
    .fetchContestState()
    .then(({ data }) => {
      dispatch({ type: "fetch_contest_state_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";
      toast.error("Something is wrong with my team");
      dispatch({ type: "fetch_contest_state_error", payload: message });
    });
};

// create stream
export const fetchContestParticipants = () => (dispatch) => {
  dispatch({ type: "fetch_contest_participants_start" });

  requests
    .fetchContestParticipants()
    .then(({ data }) => {
      dispatch({ type: "fetch_contest_participants_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";
      toast.error("Something is wrong with my team");
      dispatch({ type: "fetch_contest_participants_error", payload: message });
    });
};

// create stream
export const fetchContestJoin = () => (dispatch) => {
  dispatch({ type: "join_contest_start" });

  requests
    .fetchContestJoin()
    .then(({ data }) => {
      dispatch({ type: "join_contest_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) ||
        "Foydalanuvchi profilini yuklashda xatolik bor";
      toast.error("Something is wrong with my team");
      dispatch({ type: "join_contest_error", payload: message });
    });
};

// logout
export const logout = (params) => (dispatch) => {
  dispatch({ type: "logout", payload: params });
  window.localStorage.removeItem("@jsonWepToken");
  window.location.reload();
};
