import React from "react";
import { Skeleton } from "./Skeleton";

const StreamAllLoading = () => {
  return (
    <div className="stream__cart">
      <div className="stream__cart__image__ramce">
        <Skeleton className="stream__cart__image__ramce__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="stream__cart__body">
        <div className="stream__cart__body__title__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
        <div className="stream__cart__body__price__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
        <div className="stream__cart__body__price__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
        <div className="stream__cart__body__price__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
        <div className="stream__cart__body__input__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
        <div className="stream__cart__body__input__loading__parent">
          <Skeleton type="stream__cart__body__title__loading" />
          <div className="shimmer-wrapper">
            <div className="shimmer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamAllLoading;
