import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { MContainer } from "../../element/Elemens";
import { fetchByStreamStatistic } from "../../redux/actions/streamActions";

const columns = [
  {
    name: "Oqim",
    selector: "desc"
  },
  {
    name: "Tashrif",
    selector: "visits.count"
  },
  {
    name: "Yangi",
    selector: "new.count"
  },
  {
    name: "Qabul Qilindi",
    selector: "accepted.count"
  },
  {
    name: "Yetkazilmoqda",
    selector: "sent.count"
  },
  {
    name: "Yetkazib Berildi",
    selector: "delivered.count"
  },
  {
    name: "Qayta Qo'ngiroq",
    selector: "pending.count"
  },
  {
    name: "Spam",
    selector: "spam.count"
  },
  {
    name: "Qaytib Keldi",
    selector: "cancelled.count"
  },
  {
    name: "HOLD",
    selector: "hold.count"
  },
  {
    name: "Arxivlandi",
    selector: "archieved.count"
  }
];

const MyRequests = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchByStreamStatistic());
  }, []);

  const streamByStatic = useSelector((state) => state.stream.list);
  const streamMeta = useSelector((state) => state.stream.meta);
  const { loading } = useSelector((state) => state.stream);

  const handlePageChange = (page) => {
    dispatch(fetchByStreamStatistic({ page: page, per_page: 10 }));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(fetchByStreamStatistic({ page: page, per_page: newPerPage }));
  };

  return (
    <MContainer>
      <DataTable
        columns={columns}
        data={streamByStatic}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        paginationServer
        highlightOnHover
        progressPending={loading}
        paginationTotalRows={streamMeta.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </MContainer>
  );
};

export default MyRequests;
