import React, { useState, useEffect } from "react";
import { MContainer, MLink } from "../../element/Elemens";
import "../../assets/scss/_product.scss";
import { FiHeart } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import LoadingPage from "../../component/loading/page/LoadingPage";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../redux/actions/productActions";
import InfiniteScroll from "react-infinite-scroll-component";
import CurrencyFormat from "react-currency-format";
const URLUztez = `${process.env.REACT_APP_API_DOMAIN}`;

const Product = () => {
  const dispatch = useDispatch();
  const [productFilter, setProductFilter] = useState({ page: 1, per_page: 20 });

  useEffect(() => {
    dispatch(fetchProducts(productFilter));
  }, []);

  const productList = useSelector((state) => state.product.list);
  const { loading, hasMore } = useSelector((state) => state.product);

  function fetchMore() {
    let newFilterProduct = {
      ...productFilter,
      page: productFilter.page + 1
    };
    setProductFilter(newFilterProduct);
    dispatch(fetchProducts(newFilterProduct));
  }

  return (
    <MContainer>
      <InfiniteScroll
        dataLength={productList.length}
        next={fetchMore}
        hasMore={hasMore}
      >
        <div className="product__grid">
          {productList.map((product, index) => (
            <div key={index} className="product__cart">
              <div className="product__cart__love">
                <FiHeart className="product__cart__love__icon" />
              </div>
              <MLink to={`/product/order/` + product.id}>
                <div className="product__cart__image__ramci">
                  <img
                    src={URLUztez + `uploads/${product.main_image}`}
                    alt="Mahsulot rasmi"
                  />
                </div>
              </MLink>
              <div className="product__cart__body">
                <span className="product__cart__title">
                  {product.title.slice(0, 35)}
                </span>

                <div className="cart__informasion__price__seen">
                  <span className="product__cart__price">
                    <CurrencyFormat
                      value={product.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />{" "}
                    so'm
                  </span>
                  <span className="product__cart__most__seen">
                    <BsEyeFill />
                    {product.views}
                  </span>
                </div>

                <MLink to={`/product/order/` + product.id}>
                  <button className="product__cart__order__button">
                    Buyurtma berish
                  </button>
                </MLink>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
      {loading && <LoadingPage />}
    </MContainer>
  );
};

export default Product;
