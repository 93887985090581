import React, { useEffect } from "react";
import { MContainer, MLink } from "../../element/Elemens";
import "../../assets/scss/_profile.scss";
import { MdMonetizationOn } from "react-icons/md";
import { FcBarChart } from "react-icons/fc";
import { GiPresent } from "react-icons/gi";
import CurrencyFormat from "react-currency-format";
import { AiOutlineFundView } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchStreamClent } from "../../redux/actions/streamActions";
import { ProfileLoadingGlavni } from "../../component/loading/profile/ProfileLoadingGlavni";
import moment from "moment";
import "moment/locale/uz-latn.js";
moment.locale("uz-latn");
const URLUztez = `${process.env.REACT_APP_API_DOMAIN}`;

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStreamClent());
  }, []);

  const userInfo = useSelector((state) => state.user.userInfo);
  const { loading } = useSelector((state) => state.user);

  return (
    <MContainer>
      {loading ? (
        <ProfileLoadingGlavni />
      ) : (
        <div className="grid__cart">
          <MLink to="/profile/profile-edit">
            <div className="profile__cart">
              <div className="profile__cart__body">
                <div className="profile__cart__row">
                  <div className="profile__cart__image__ramci">
                    <img
                      src={URLUztez + `uploads/${userInfo.avatar_url}`}
                      alt="User no"
                    />
                  </div>
                  <h3 className="profile__cart__user__fullName">
                    {userInfo.first_name} {userInfo.last_name}
                  </h3>
                </div>
                <div className="profile__cart__user__information">
                  <div className="profile__cart__user__basic__user__information">
                    <span className="profile__cart__user__basic__user__information__text">
                      Karta raqam:
                    </span>
                    8600 **** **65 4321
                  </div>
                </div>
                <div className="profile__cart__user__information">
                  <div className="profile__cart__user__basic__user__information">
                    <span className="profile__cart__user__basic__user__information__text">
                      Telefon raqam:
                    </span>
                    <CurrencyFormat
                      format="+### (##) ###-##-##"
                      value={userInfo.phone_number}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </MLink>

          <div className="profile__cart">
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <MdMonetizationOn size={80} />
              </div>
              <h3 className="profile__cart__user__fullName">
                <CurrencyFormat
                  value={userInfo.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                so'm
              </h3>
            </div>
            <div className="profile__cart__user__information">
              <div className="profile__cart__user__basic__user__information">
                <span className="profile__cart__user__basic__user__information__text">
                  Tahminiy balans:
                </span>
                <CurrencyFormat
                  value={userInfo.estimated_balance}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                so'm
              </div>
            </div>
            <div className="profile__cart__user__information">
              <div className="profile__cart__user__basic__user__information">
                {moment(userInfo.created_at).fromNow(true)} dan buyon Uztez
                sotuvchisi
              </div>
            </div>
          </div>

          <div className="profile__cart">
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <h1>{userInfo.total_orders}</h1>
              </div>
              <h3 className="profile__cart__user__fullName">Dinar</h3>
            </div>
            <div className="profile__cart__user__information">
              <div className="profile__cart__user__basic__user__information">
                <span className="profile__cart__user__basic__user__information__text">
                  Xar bir sotilgan mahsulotga qarab dinar to'lab beriladi
                </span>
              </div>
            </div>
          </div>

          <MLink to="/profile/order-state">
            <div className="profile__cart">
              <div className="profile__cart__row">
                <div className="profile__cart__image__ramci">
                  <FcBarChart size={60} />
                </div>
                <h3 className="profile__cart__user__fullName">
                  Buyurtmalar holati
                </h3>
              </div>
              <div className="profile__cart__user__information">
                <div className="profile__cart__user__basic__user__information">
                  <span className="profile__cart__user__basic__user__information__text">
                    Buyurtmalaringiz haqida ko'proq ma'lumot olish
                  </span>
                </div>
              </div>
            </div>
          </MLink>

          <MLink to="/profile/competition">
            <div className="profile__cart">
              <div className="profile__cart__row">
                <div className="profile__cart__image__ramci">
                  <GiPresent size={60} style={{ color: "black" }} />
                </div>
                <h3 className="profile__cart__user__fullName">Super Konkurs</h3>
              </div>
              <div className="profile__cart__user__information">
                <div className="profile__cart__user__basic__user__information">
                  <span className="profile__cart__user__basic__user__information__text">
                    G'oliblikga davogarlik qilish uchun "DINAR"ga asoslangan
                  </span>
                </div>
              </div>
            </div>
          </MLink>

          {/* <MLink to="/profile/my-statistics">
            <div className="profile__cart">
              <div className="profile__cart__row">
                <div className="profile__cart__image__ramci">
                  <MdQueryStats size={45} style={{ color: "black" }} />
                </div>
                <h3 className="profile__cart__user__fullName">Statistika</h3>
              </div>
              <div className="profile__cart__user__information">
                <div className="profile__cart__user__basic__user__information">
                  <span className="profile__cart__user__basic__user__information__text">
                    Ozizga tegishli barcha hisobotlar
                  </span>
                </div>
              </div>
            </div>
          </MLink> */}

          <div
            className="profile__cart"
            onClick={() =>
              window.open(`https://t.me/uztez_uz_bot?start=${userInfo.id}`)
            }
          >
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <AiOutlineFundView size={60} style={{ color: "black" }} />
              </div>
              <h3 className="profile__cart__user__fullName">Botni ishlatish</h3>
            </div>
            <div className="profile__cart__user__information">
              <div className="profile__cart__user__basic__user__information">
                <span className="profile__cart__user__basic__user__information__text">
                  So'rovlar tasdiqlangandan so'ng xabarnoma jo'natish
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </MContainer>
  );
};

export default Profile;
