const INITIAL_STATE = {
  list: [],
  stream_client_list: [],
  loading: false,
  message: null,
  sidebarShow: "responsive",
  meta: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // create transaction
    case "create_transaction_start":
      return { ...state, loading: true, message: "" };
    case "create_transaction_error":
      return { ...state, message: payload, loading: false };
    case "create_transaction_success":
      return {
        ...state,
        loading: false,
        list: [...state.list, payload.data]
      };

    // get all transaction
    case "fetch_transactions_start":
      return { ...state, loading: true, message: "" };
    case "fetch_transactions_error":
      return { ...state, message: payload, loading: false };
    case "fetch_transactions_success":
      return {
        ...state,
        loading: false,
        list: payload.data,
        meta: payload.meta
      };

    // get all transaction
    case "fetch_payment_request_start":
      return { ...state, loading: true, message: "" };
    case "fetch_payment_request_error":
      return { ...state, message: payload, loading: false };
    case "fetch_payment_request_success":
      return {
        ...state,
        loading: false,
        list: payload.data
      };

    /* other */
    default:
      return state;
  }
};
