const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  token: null,
  message: null,
  sidebarShow: "responsive",
  userInfo: {},
  data: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // auth
    case "auth_password_start":
      return { ...state, loading: true, message: "" };
    case "auth_password_error":
      return { ...state, message: payload, loading: false };
    case "auth_password_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        loginStep: 1
      };

    // Login
    case "auth_login_start":
      return { ...state, loading: true, message: "", success: false };
    case "auth_login_error":
      return { ...state, message: payload, loading: false };
    case "auth_login_success":
      window.localStorage.setItem("@jsonWepToken", payload.data.token);
      window.localStorage.setItem(
        "@jsonWepRefreshToken",
        payload.data.refreshToken
      );
      return {
        ...state,
        loading: false,
        token: payload.data.token,
        message: payload.message
      };
    case "back_to_password":
      return {
        ...state,
        loginStep: 0
      };

    // get me
    case "profile_info_start":
      return { ...state, loading: true, message: "" };
    case "profile_info_error":
      return { ...state, message: payload, loading: false };
    case "profile_info_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        userInfo: payload.data
      };

    // get me
    case "update_profile_start":
      return { ...state, loading: true, message: "" };
    case "update_profile_error":
      return { ...state, message: payload, loading: false };
    case "update_profile_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        userInfo: payload.data
      };

    // get me
    case "fetch_my_team_start":
      return { ...state, loading: true, message: "" };
    case "fetch_my_team_error":
      return { ...state, message: payload, loading: false };
    case "fetch_my_team_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data
      };

    // get me
    case "fetch_contest_state_start":
      return { ...state, loading: true, message: "" };
    case "fetch_contest_state_error":
      return { ...state, message: payload, loading: false };
    case "fetch_contest_state_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data
      };

    // get me
    case "fetch_contest_participants_start":
      return { ...state, loading: true, message: "" };
    case "fetch_contest_participants_error":
      return { ...state, message: payload, loading: false };
    case "fetch_contest_participants_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data
      };

    // get me
    case "join_contest_start":
      return { ...state, loading: true, message: "" };
    case "join_contest_error":
      return { ...state, message: payload, loading: false };
    case "join_contest_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: [...state.list, payload.data]
      };

    // profile
    case "logout":
      return { ...INITIAL_STATE };

    //sidebar toggle reducer
    case "sidebar_toggle":
      return { ...state, ...rest };

    /* other */
    default:
      return state;
  }
};
