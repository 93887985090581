import requests from "../../helpers/requests";

// Clent stream
export const fetchStreamClent = (params) => (dispatch) => {
  dispatch({ type: "fetch_user_stream_start" });

  requests
    .fetchStreamClent(params)
    .then(({ data }) => {
      dispatch({ type: "fetch_user_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening oqimlarim topilmadi";

      dispatch({ type: "fetch_user_stream_error", payload: message });
    });
};

// Clent stream
export const fetchStreamStatistic = () => (dispatch) => {
  dispatch({ type: "fetch_statistic_stream_start" });

  requests
    .fetchStreamStatistic()
    .then(({ data }) => {
      dispatch({ type: "fetch_statistic_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening oqimlarim topilmadi";

      dispatch({ type: "fetch_statistic_stream_error", payload: message });
    });
};

// Clent stream
export const updateStreamAgent = (id, data) => (dispatch) => {
  dispatch({ type: "update_agent_stream_start" });

  requests
    .updateStreamAgent(id, data)
    .then(({ data }) => {
      dispatch({ type: "update_agent_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening oqimlarim topilmadi";

      dispatch({ type: "update_agent_stream_error", payload: message });
    });
};

// Clent stream
export const updateStreamAgentDelivery = (id, data) => (dispatch) => {
  dispatch({ type: "update_agent_delivery_stream_start" });

  requests
    .updateStreamAgentDelivery(id, data)
    .then(({ data }) => {
      dispatch({ type: "update_agent_delivery_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening oqimlarim topilmadi";

      dispatch({
        type: "update_agent_delivery_stream_error",
        payload: message
      });
    });
};

// Clent stream
export const fetchByStreamStatistic = (params) => (dispatch) => {
  dispatch({ type: "fetch_by_statistic_stream_start" });

  requests
    .fetchByStreamStatistic(params)
    .then(({ data }) => {
      dispatch({ type: "fetch_by_statistic_stream_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening oqimlarim topilmadi";

      dispatch({ type: "fetch_by_statistic_stream_error", payload: message });
    });
};
