import React, { useEffect, useState } from "react";
import { MButton, MContainer } from "../../element/Elemens";
import { AiOutlineDelete, AiFillEye } from "react-icons/ai";
import {
  BsFillBagCheckFill,
  BsFillBellFill,
  BsCalendarDateFill
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { FaTelegramPlane, FaCopy } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { IoMdClose } from "react-icons/io";
import { GiReceiveMoney } from "react-icons/gi";
import {
  fetchStreamClent,
  updateStreamAgent,
  updateStreamAgentDelivery
} from "../../redux/actions/streamActions";
import { toast } from "react-toastify";
import "../../assets/scss/_stream.scss";
import "react-toastify/dist/ReactToastify.css";
import requests from "../../helpers/requests";
import CurrencyFormat from "react-currency-format";
import ResponsivePagination from "react-responsive-pagination";
import StreamAllLoading from "../../component/loading/stream/stream_all_loading";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import moment from "moment";
import "moment/locale/uz-latn.js";
moment.locale("uz-latn");

const Stream = () => {
  const dispatch = useDispatch();
  const [show_delete_stream, set_show_delete_stream] = useState(false);
  const [stream_id, set_stream_id] = useState();

  useEffect(() => {
    dispatch(fetchStreamClent());
  }, []);

  // Delete stream
  const deleteStream = (id) => {
    dispatch({ type: "delete_stream_start", payload: id });
    requests
      .deleteStream(id)
      .then(({ data }) => {
        dispatch({
          type: "delete_stream_success",
          payload: { data, stream_id: id }
        });
        set_show_delete_stream(false);
      })
      .catch(({ response }) => {
        let message = "Oqimlar uchirilmadi";
        dispatch({ type: "delete_stream_error", payload: message });
      });
  };

  const streamUser = useSelector((state) => state.stream.stream_client_list);
  const { loading, meta, loading_stream } = useSelector(
    (state) => state.stream
  );

  useEffect(() => {
    if (meta.current_page) {
      window.scrollTo(0, 0);
    }
  }, [meta.current_page]);

  return (
    <MContainer>
      {loading ? (
        <div className="stream__grid">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <StreamAllLoading key={item} />
          ))}
        </div>
      ) : (
        <>
          {streamUser && streamUser.length >= 1 ? (
            <div>
              <h2 className="stream__title">Mening oqimlarim</h2>
              <div className="stream_agent_informasion">
                Ozingiz hohlagan holda reklama qilishingiz mumkun yani "Yetkazib
                berish tekin" yoki "Yetkazib berish xizmati mavjud" deb, birgina
                pitichkani bilgilashingizga bog'liq, operatorlar sizni
                "Reklama"gizga qarab tasdiqlaydi
                <div className="stream_agent_informasion_text_aligin">
                  1 tadan ortiq "Mahsulot" sotib olganda qanaqa holat bo'lsa ham
                  dostavka tekin bo'ladi
                </div>
              </div>
              <div className="stream__grid">
                {streamUser.map((stream, index) => (
                  <div key={index} className="stream__cart stream__cart__user">
                    <div className="stream__cart__delete__icon">
                      <AiOutlineDelete
                        size={25}
                        className="stream__delete__icon"
                        onClick={() => {
                          set_stream_id(stream.id);
                          set_show_delete_stream(true);
                        }}
                      />
                    </div>

                    <div className="stream__cart__body">
                      <h5 className="stream__cart__body__title">
                        {stream.product?.title}
                      </h5>

                      <div className="stream__cart__body__information">
                        <div className="stream_cart_info_text">
                          <BsFillBellFill /> Kanal nomi: {stream.desc}
                        </div>
                        <div className="stream_cart_info_text">
                          <AiFillEye /> Namoyishlar: {stream.visits} ta
                        </div>
                        <div className="stream_cart_info_text">
                          <BsCalendarDateFill /> Yaratildi:{" "}
                          {moment(stream.created_at).calendar()}
                        </div>
                        <div className="stream_cart_info_text">
                          <BsFillBagCheckFill /> Mahsulot narxi:{" "}
                          <CurrencyFormat
                            value={stream.product?.price}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          so'm
                        </div>
                        <div className="stream_cart_info_text">
                          <GiReceiveMoney /> Oqim narxi:{" "}
                          <CurrencyFormat
                            value={stream.product?.stream_price}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          so'm
                        </div>
                      </div>
                      <input
                        className="stream__cart__body__input"
                        disabled
                        defaultValue={`https://uztez.uz/stream/${stream.stream_id}`}
                      />

                      <div className="stream__region__confirm">
                        {!loading_stream ? (
                          <Checkbox
                            checked={stream.delivery_included}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  backgroundColor: "#174A41",
                                  alignSelf: "stretch"
                                }}
                              >
                                <Icon.FiCheck color="white" size={20} />
                              </div>
                            }
                            onChange={(e) => {
                              dispatch(
                                updateStreamAgentDelivery(stream.id, {
                                  delivery_included: e
                                })
                              );
                            }}
                            borderColor="#174A41"
                            borderRadius={20}
                            style={{ overflow: "hidden" }}
                            size={20}
                            label="Yetkazib berish pullik"
                          />
                        ) : (
                          "Tayyorlanmoqda"
                        )}
                      </div>

                      <div className="stream_delivery_control">
                        Operator tomonidan pul qo'shiladi
                      </div>

                      <MButton
                        className="stream__button"
                        onClick={() =>
                          window.open(`${stream.product?.telegram_link}`)
                        }
                      >
                        Telegram Link
                        <FaTelegramPlane className="stream__telegram__icon" />
                      </MButton>
                      <MButton
                        className="stream__button"
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://uztez.uz/stream/${stream.stream_id}`
                          );
                          toast("Oqimdan nusxa olindi!");
                        }}
                      >
                        Copy <FaCopy style={{ marginLeft: 10 }} />
                      </MButton>

                      <div className="stream__region__confirm">
                        {!loading_stream ? (
                          <Checkbox
                            checked={stream.no_address}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  backgroundColor: "#174A41",
                                  alignSelf: "stretch"
                                }}
                              >
                                <Icon.FiCheck color="white" size={20} />
                              </div>
                            }
                            onChange={(e) => {
                              dispatch(
                                updateStreamAgent(stream.id, {
                                  no_address: e
                                })
                              );
                            }}
                            borderColor="#174A41"
                            borderRadius={20}
                            style={{ overflow: "hidden" }}
                            size={20}
                            label="Viloyat kirak emas"
                          />
                        ) : (
                          "Tayyorlanmoqda"
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="stream__page__not">
              <h1>"Sizda oqimlar yuq"</h1>
            </div>
          )}
        </>
      )}

      <ResponsivePagination
        current={meta.current_page}
        total={meta.last_page}
        onPageChange={(e) =>
          dispatch(fetchStreamClent({ page: e, per_page: 20 }))
        }
      />

      <Modal
        open={show_delete_stream}
        onClose={() => {
          set_show_delete_stream(false);
        }}
        className="stream_responsive_modal_modal"
        aria-labelledby="my-modal-title"
        blockScroll={false}
        animationDuration={400}
        closeIcon={<IoMdClose style={{ color: "white" }} size={20} />}
      >
        <h3 id="my-modal-title">O'chirmoqchimisiz?</h3>
        <div className="stream__modal__body">
          <div className="stream__modal__form__text">
            Diqqat! Oqim o'chirilsa sizdan ushbu oqim bo'yicha so'rovlar qabul
            qilinmaydi
          </div>
          <div className="stream__modal__button__box">
            <button
              className="stream__modal__button__left"
              onClick={() => {
                dispatch(deleteStream(stream_id));
              }}
            >
              O'chirilsin
            </button>
            <button
              onClick={() => {
                set_show_delete_stream(false);
              }}
            >
              Orqaga
            </button>
          </div>
        </div>
      </Modal>
    </MContainer>
  );
};

export default Stream;
