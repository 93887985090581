const INITIAL_STATE = {
  list: [],
  stream_client_list: [],
  loading: false,
  loading_stream: false,
  message: null,
  sidebarShow: "responsive",
  meta: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // fetch stream user
    case "fetch_user_stream_start":
      return { ...state, loading: true, message: "" };
    case "fetch_user_stream_error":
      return { ...state, message: payload, loading: false };
    case "fetch_user_stream_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        stream_client_list: payload.data,
        meta: payload.meta
      };

    // fetch stream user
    case "fetch_statistic_stream_start":
      return { ...state, loading: true, message: "" };
    case "fetch_statistic_stream_error":
      return { ...state, message: payload, loading: false };
    case "fetch_statistic_stream_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data
      };

    // fetch stream user
    case "fetch_by_statistic_stream_start":
      return { ...state, loading: true, message: "" };
    case "fetch_by_statistic_stream_error":
      return { ...state, message: payload, loading: false };
    case "fetch_by_statistic_stream_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
        meta: payload.meta
      };

    // fetch stream all
    case "fetch_stream_all_start":
      return { ...state, loading: true, message: "" };
    case "fetch_stream_all_error":
      return { ...state, message: payload, loading: false };
    case "fetch_stream_all_success":
      return {
        ...state,
        loading: false,
        list: payload.data
      };

    // create stream
    case "create_stream_start":
      return { ...state, loading: true, message: "" };
    case "create_stream_error":
      return { ...state, message: payload, loading: false };
    case "create_stream_success":
      return {
        ...state,
        loading: false
      };

    // create stream
    case "update_agent_stream_start":
      return { ...state, loading_stream: true, message: "" };
    case "update_agent_stream_error":
      return { ...state, message: payload, loading_stream: false };
    case "update_agent_stream_success":
      let updateStream;
      state.stream_client_list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          updateStream = index;
        }
      });
      state.stream_client_list[updateStream] = payload.data;

      return {
        ...state,
        loading_stream: false,
        list: [...state.stream_client_list]
      };

    // create stream
    case "update_agent_delivery_stream_start":
      return { ...state, loading_stream: true, message: "" };
    case "update_agent_delivery_stream_error":
      return { ...state, message: payload, loading_stream: false };
    case "update_agent_delivery_stream_success":
      let updateStreamDelivery;
      state.stream_client_list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          updateStreamDelivery = index;
        }
      });
      state.stream_client_list[updateStreamDelivery] = payload.data;

      return {
        ...state,
        loading_stream: false,
        list: [...state.stream_client_list]
      };

    // delet stream
    case "delete_stream_start":
      return { ...state, loading: true, message: "" };
    case "delete_stream_error":
      return { ...state, message: payload, loading: false };
    case "delete_stream_success":
      const newList =
        state.stream_client_list.filter(
          (item) => item.id !== payload.stream_id
        ) || [];
      return {
        ...state,
        loading: false,
        stream_client_list: newList
      };

    /* other */
    default:
      return state;
  }
};
