import React, { useEffect } from "react";
import { MContainer } from "../../element/Elemens";
import "../../assets/scss/_transaction.scss";
import TransactionRequest from "../../component/Transaction/TransactionRequest";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  createPaymentRequest,
  fetchPaymentRequest
} from "../../redux/actions/transactionActions";
import {
  columns,
  conditionalRowStyles
} from "../../component/Transaction/Element";

const Transaction = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaymentRequest());
  }, []);

  const transaction_info = useSelector((state) => state.transaction.list);
  const { loading } = useSelector((state) => state.transaction);

  return (
    <MContainer>
      <div className="transaction__page__row">
        <div>
          <h2 className="transaction__page__title">To'lovga so'rov berish</h2>
          {userInfo.id && (
            <TransactionRequest
              userInfo={userInfo}
              loading={loading}
              onClickTransaction={(transaction) => {
                dispatch(createPaymentRequest(transaction));
              }}
            />
          )}
        </div>
        <div className="transaction__page__rigth">
          <h2 className="transaction__page__title">Mening amalyotlarim</h2>
          <div className="transaction__page__table">
            <DataTable
              columns={columns}
              data={transaction_info}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              pagination
              highlightOnHover
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </div>
      </div>
    </MContainer>
  );
};

export default Transaction;
