import axios from "axios";

const url = `${process.env.REACT_APP_API_DOMAIN}api`;
const token = window.localStorage.getItem("@jsonWepToken");
const config = {
  headers: { Authorization: `Bearer ${token}` }
};

const packageData = (data) => {
  const form = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const childKey in data[key]) {
        form.append(`${key}[${childKey}]`, data[key][childKey]);
      }
    } else {
      form.append(key, data[key]);
    }
  }
  return form;
};

const requests = {
  authPassword: (username) =>
    axios.post(`${url}/clients/login-register`, username),
  authLogin: (username) => axios.post(`${url}/clients/verify`, username),
  getMe: () => axios.get(`${url}/users/me`, config),
  updateProfile: (params) =>
    axios.patch(`${url}/users/me`, packageData(params), config),
  // Product
  fetchProducts: (params) =>
    axios.get(`${url}/products`, { params, ...config }),
  fetchProductsStream: (params) =>
    axios.get(`${url}/products?filters[in_stream]=true`, { params, ...config }),
  fetchProductOne: (id) => axios.get(`${url}/products/${id}`, config),
  // Order
  createOrder: (params) => axios.post(`${url}/orders/quick`, params),
  updateOrderNew: (id) =>
    axios.post(`${url}/orders/${id}/recheck`, null, config),
  // Order stream state
  orderChartStreamState: (id) => axios.get(`${url}/orders/stream-income/${id}`),
  // Stream
  fetchStreamClent: (params) =>
    axios.get(`${url}/streams`, { params, ...config }),
  fetchStreamStatistic: () => axios.get(`${url}/streams/statistics`, config),
  fetchByStreamStatistic: (params) =>
    axios.get(`${url}/streams/statistics/by-stream`, { params, ...config }),
  createStream: (params) => axios.post(`${url}/streams/join`, params, config),
  deleteStream: (id) => axios.delete(`${url}/streams/${id}`, config),
  updateStreamAgent: (id, data) =>
    axios.patch(`${url}/streams/${id}`, data, config),
  updateStreamAgentDelivery: (id, data) =>
    axios.patch(`${url}/streams/${id}`, data, config),
  // Transaction request
  fetchPaymentRequest: () => axios.get(`${url}/payment-requests`, config),
  createPaymentRequest: (params) =>
    axios.post(`${url}/payment-requests`, params, config),
  fetchTransactions: (params) =>
    axios.get(`${url}/transactions`, { params, ...config }),
  // Locations
  getLocations: () => axios.get(`${url}/regions`),
  // Locations
  fetchProductWiew: () => axios.get(`${url}/products/most/view/products`),
  // Locations
  fetchAgentOrders: (params) =>
    axios.get(`${url}/streams/orders`, { params, ...config }),
  // Locations
  fetchBestSellingProduct: () =>
    axios.get(`${url}/orders/best/selling/products`),
  fetchContestState: () => axios.get(`${url}/contest/state`, config),
  fetchContestParticipants: () =>
    axios.get(`${url}/contest/participants`, config),
  fetchContestJoin: () => axios.post(`${url}/contest/join`, null, config),

  orderUpdatePending: (id) =>
    axios.put(`${url}/orders/${id}/pending`, null, config)
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("err", error);

    if (error.response.status === 401) {
      window.localStorage.removeItem("@jsonWebTokenManager");
      window.location.reload();
    }

    if (error.response.status !== 406) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    const refreshToken = window.localStorage.getItem("@jsonWepRefreshToken");

    return axios
      .post(`${url}/users/refresh`, { refreshToken })
      .then(({ data }) => {
        window.localStorage.setItem("@jsonWepToken", data.data.token);

        config.headers = { Authorization: `Bearer ${data.data.token}` };

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((err) => {
        return new Promise((resolve, reject) => {
          reject(err);
        });
      });
  }
);

export default requests;
