import React, { useState, useEffect } from "react";
import "../assets/scss/_navbar.scss";
import {
  MContainer,
  MLink,
  MNavbar,
  MNavbarTop,
  MNav
} from "../element/Elemens";
import { GoLocation } from "react-icons/go";
import { SiHomeassistant } from "react-icons/si";
import { FaUserAlt, FaHeart } from "react-icons/fa";
import { BsBookmarkHeart, BsSearch } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLocations } from "../redux/actions/locationActions";
import Logo from "../assets/images/123.png";

const Navbar = () => {
  const dispatch = useDispatch();

  const [fixedNavbar, setFixedNavbar] = useState(false);
  const [fixedNavbarResponse, setFixedNavbarResponse] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const changeScrollNavbar = () => {
    if (window.scrollY >= 45) {
      setFixedNavbar(true);
    } else {
      setFixedNavbar(false);
    }
  };

  window.addEventListener("scroll", changeScrollNavbar);
  const changeScrollNavbarResponse = () => {
    if (window.scrollY >= 50) {
      setFixedNavbarResponse(true);
    } else {
      setFixedNavbarResponse(false);
    }
  };
  window.addEventListener("scroll", changeScrollNavbarResponse);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  return (
    <div className="navbar">
      <div className="navbar__nav">
        <MNavbarTop>
          <MContainer>
            <div className="navbar__item">
              <div className="nav__item">
                <span className="nav__item__location">
                  <GoLocation
                    size={12}
                    className="nav__item__location__icons"
                  />
                  O'zbekiston
                  <div className="nav__item__delivery__info">
                    Yetkazib berish xizmati mavjud
                  </div>
                </span>
              </div>
              <div className="nav__item">
                <div className="nav__item__language">
                  <span className="nav__item__call__center">
                    +998 91 013 00 34
                  </span>
                  <span>UZ</span>
                </div>
              </div>
            </div>
          </MContainer>
        </MNavbarTop>
        <MNavbar
          positionFixed={fixedNavbar}
          positionFixedResponse={fixedNavbarResponse}
        >
          <MContainer>
            <div className="navbar__item__global">
              <div className="nav__item__brand">
                <div className="nav__logo">
                  <img src={Logo} alt="Uztez" />
                </div>
                <div className="nav__item__category">
                  <div>Category</div>
                </div>
              </div>
              <div className="nav__item__search">
                <input
                  className="nav__item__search__input"
                  placeholder="Search"
                />
              </div>
              <div className="nav__item__icons">
                <MLink exact to={"/"}>
                  <MNav className={splitLocation[1] === "" ? "active" : ""}>
                    <SiHomeassistant size={22} />
                  </MNav>
                </MLink>
                <MLink to={"/products"}>
                  <MNav
                    className={splitLocation[1] === "products" ? "active" : ""}
                  >
                    <BsSearch size={22} />
                  </MNav>
                </MLink>
                <MLink to={"/star"}>
                  <MNav className={splitLocation[1] === "star" ? "active" : ""}>
                    <FaHeart size={22} />
                  </MNav>
                </MLink>
                <MLink to={"/product"}>
                  <MNav
                    className={splitLocation[1] === "product" ? "active" : ""}
                  >
                    <BsBookmarkHeart size={22} />
                  </MNav>
                </MLink>
                <MLink to="/profile/profile-info">
                  <MNav
                    className={`${
                      splitLocation[1] === "profile" ? "active" : ""
                    } nav__item__icon__rigth`}
                  >
                    <FaUserAlt size={22} />
                  </MNav>
                </MLink>
              </div>
            </div>
          </MContainer>
        </MNavbar>
      </div>
    </div>
  );
};

export default Navbar;
