import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const MContainer = styled.div`
  width: 90%;
  margin: 0 auto;

  @media (max-width: 780px) {
    width: 92%;
  }
  @media (max-width: 600px) {
    width: 96%;
  }
`;

export const MContent = styled.div`
  width: 100%;
  height: 80vh;
`;

export const MFooter = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(12, 54, 115, 1) 0%,
    rgba(65, 115, 242, 1) 50%,
    rgba(12, 54, 115, 1) 100%
  );
  margin-top: 20px;
  @media (max-width: 780px) {
    margin-bottom: 58px;
  }
`;

export const MNavbarTop = styled.div`
  height: 35px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const MLink = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  color: white;
`;

export const MNav = styled.div`
  margin: 0 20px;
  cursor: pointer;
  color: white;
`;

export const MHeader = styled.div`
  background: linear-gradient(
    90deg,
    rgba(12, 54, 115, 1) 0%,
    rgba(65, 115, 242, 1) 50%,
    rgba(12, 54, 115, 1) 100%
  );
  padding: 15px 0px;
  margin-top: 1px;
`;

export const MNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  color: white;
  height: 60px;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(12, 54, 115, 1) 0%,
    rgba(65, 115, 242, 1) 50%,
    rgba(12, 54, 115, 1) 100%
  );
  z-index: 10;
  @media only screen and (min-width: 568px) {
    position: ${(props) => (props.positionFixed ? "fixed" : "")};
  }
  @media only screen and (max-width: 568px) {
    position: ${(props) => (props.positionFixedResponse ? "fixed" : "")};
  }
`;

export const MButton = styled.button`
  padding: 8px;
  width: -webkit-fill-available;
  border: 1px solid #0f3979;
  outline: none;
  border-radius: 8px;
  background-color: #0f3979;
  color: white;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
