import { combineReducers } from "redux";
import userReducer from "./userReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";
import chartReducer from "./chartReducer";
import locationReducer from "./locationReducer";
import streamReducer from "./streamReducer";
import transactionReducer from "./transactionReducer";

const reducer = combineReducers({
  user: userReducer,
  product: productReducer,
  orders: orderReducer,
  locations: locationReducer,
  stream: streamReducer,
  transaction: transactionReducer,
  chart: chartReducer,
});

export default reducer;
