import React from "react";
import { Skeleton } from "./Skeleton";
import "../../../assets/scss/_profile.scss";

export const OrderStatusLoading = () => {
  return (
    <div className="grid__order__information">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
        return (
          <div className="profile__cart__order_skeleton" key={item}>
            <div className="skeleton_order_status_body">
              <div className="product__order__form__title__parent">
                <Skeleton type="product__order__form__title" />
                <div className="shimmer-wrapper">
                  <div className="shimmer"></div>
                </div>
              </div>
              <div className="skeleton_order_status_body__parent">
                <Skeleton type="skeleton_order_status_body__loading" />
                <div className="shimmer-wrapper">
                  <div className="shimmer"></div>
                </div>
              </div>
              <div className="product__order__form__title__parent">
                <Skeleton type="product__order__form__title" />
                <div className="shimmer-wrapper">
                  <div className="shimmer"></div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
