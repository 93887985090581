import React, { useEffect } from "react";
import { MContainer } from "../../element/Elemens";
import "../../assets/scss/_transaction.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../redux/actions/transactionActions";
import moment from "moment";
import "moment/locale/uz-latn.js";
import ResponsivePagination from "react-responsive-pagination";
import CurrencyFormat from "react-currency-format";
moment.locale("uz-latn");

const MyTransaction = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTransactions({ page: 1, per_page: 40 }));
  }, []);

  const transactions = useSelector((state) => state.transaction.list);
  const { meta } = useSelector((state) => state.transaction);

  useEffect(() => {
    if (meta.current_page) {
      window.scrollTo(0, 0);
    }
  }, [meta.current_page]);

  return (
    <MContainer>
      <div className="transaction_page_static">
        <div className="transaction_page_static_row transaction_page_head">
          <div>O'tkazmalar</div>
          <div>Izoh</div>
          <div>Sana</div>
        </div>
        {transactions.map((item, index) => (
          <div className="transaction_page_static_row" key={index}>
            <div>
              <CurrencyFormat
                value={item.amount || 0}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              so'm
            </div>
            <div>{item.comment}</div>
            <div>{moment(item.created_at).fromNow(true)}</div>
          </div>
        ))}
        <div className="transaction_page_static_pagenation">
          <ResponsivePagination
            current={meta.current_page}
            total={meta.last_page}
            onPageChange={(e) =>
              dispatch(fetchTransactions({ page: e, per_page: 40 }))
            }
          />
        </div>
      </div>
    </MContainer>
  );
};

export default MyTransaction;
