import moment from "moment";
import CurrencyFormat from "react-currency-format";
import "moment/locale/uz-latn.js";
moment.locale("uz-latn");

function statusName(status) {
  switch (status) {
    case "completed":
      return "To'landi";
    case "rejected":
      return "Otkaz qilindi";
    case "pending":
      return "Ko'rib chiqilmoqda";

    default:
      break;
  }
}

export const conditionalRowStyles = [
  {
    when: (row) => row.status === "completed",
    style: {
      backgroundColor: "green",
      color: "white",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  {
    when: (row) => row.status === "rejected",
    style: {
      backgroundColor: "red",
      color: "white",
      "&:hover": {
        cursor: "pointer"
      }
    }
  }
];

export const columns = [
  {
    name: "Sana",
    cell: (row) => <div>{moment(row.created_at).fromNow(true)}</div>
  },
  {
    name: "Karta raqam",
    selector: "card_number"
  },
  {
    name: "So'mma",
    cell: (row) => (
      <div>
        <CurrencyFormat
          value={row.amount || 0}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        so'm
      </div>
    )
  },
  {
    name: "Izoh",
    selector: "comment"
  },
  {
    name: "Holat",
    cell: (row) => <div>{statusName(row.status)}</div>
  }
];
