import requests from "../../helpers/requests";

// All orders
export const orderChartStreamState = (id) => (dispatch) => {
  dispatch({ type: "fetch_orders_stream_state_start" });

  requests
    .orderChartStreamState(id)
    .then(({ data }) => {
      dispatch({
        type: "fetch_orders_stream_state_success",
        payload: data
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "fetch_orders_stream_state_error",
        payload: message
      });
    });
};
// All orders
export const fetchAgentOrders = (status) => (dispatch) => {
  dispatch({ type: "fetch_orders_agent_start" });

  requests
    .fetchAgentOrders(status)
    .then(({ data }) => {
      dispatch({
        type: "fetch_orders_agent_success",
        payload: data
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "fetch_orders_agent_error",
        payload: message
      });
    });
};

// All orders
export const orderUpdatePending = (id) => (dispatch) => {
  dispatch({ type: "update_order_pending_start" });

  requests
    .orderUpdatePending(id)
    .then(({ data }) => {
      dispatch({
        type: "update_order_pending_success",
        payload: data
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "update_order_pending_error",
        payload: message
      });
    });
};

// All orders
export const updateOrderNew = (id) => (dispatch) => {
  dispatch({ type: "update_order_new_start" });

  requests
    .updateOrderNew(id)
    .then(({ data }) => {
      dispatch({
        type: "update_order_new_success",
        payload: data
      });
    })
    .catch(({ response }) => {
      let message = (response && response.data.message) || "Mahsulot topilmadi";
      dispatch({
        type: "update_order_new_error",
        payload: message
      });
    });
};
