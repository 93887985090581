import React from "react";
import { MContainer, MLink } from "../../element/Elemens";
import "../../assets/scss/_my_statistics.scss";
import { FcStatistics } from "react-icons/fc";
import { MdOutlineTransferWithinAStation } from "react-icons/md";

const GeneralStatistics = () => {
  return (
    <MContainer>
      <div className="grid__cart__statistics">
        {/* <MLink to="/profile/my-stream-statistic">
          <div className="statistics__cart">
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <GiShower size={60} style={{ color: "black" }} />
              </div>
              <h3 className="profile__cart__user__fullName">
                Oqimlarim bo'yicha
              </h3>
            </div>
          </div>
        </MLink> */}

        <MLink to="/profile/my-transaction">
          <div className="statistics__cart">
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <MdOutlineTransferWithinAStation
                  size={60}
                  style={{ color: "black" }}
                />
              </div>
              <h3 className="profile__cart__user__fullName">
                Tranzaktsiya bo'yicha
              </h3>
            </div>
          </div>
        </MLink>

        <MLink to="/profile/my-requests">
          <div className="statistics__cart">
            <div className="profile__cart__row">
              <div className="profile__cart__image__ramci">
                <FcStatistics size={60} style={{ color: "black" }} />
              </div>
              <h3 className="profile__cart__user__fullName">
                Oqimlarim bo'yicha
              </h3>
            </div>
          </div>
        </MLink>
      </div>
    </MContainer>
  );
};

export default GeneralStatistics;
