import React, { useEffect } from "react";
import { MLink, MHeader, MContainer } from "../../element/Elemens";
import { AiOutlineHome, AiOutlineLogin } from "react-icons/ai";
import { GiWaves } from "react-icons/gi";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { BiTransferAlt } from "react-icons/bi";
import { RiShieldUserLine } from "react-icons/ri";
import "../../assets/scss/_profile.scss";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import Profile from "../profile/Profile";
import ProfileEdit from "../profile/ProfileEdit";
import StreamAll from "../stream/StreamAll";
import StatisticsPage from "../statistics/Statistics";
import MyTeamPage from "../myTeam/MyTeam";
import Transaction from "../transaction/Transaction";
import Stream from "../stream/Stream";
import Login from "../login/Login";
import { useDispatch } from "react-redux";
import { logout, profileInfo } from "../../redux/actions/userActions";
import Konkurs from "../konkurs/Konkurs";
import ViewProductsPage from "../viewsProduct/ViewProductsPage";
import BestSellingProductPage from "../bestSellingProduct/BestSellingProductPage";
import MyStatistics from "../myStatistics/MyStatistics";
import ChartStream from "../myStatistics/ChartStream";
import MyTransaction from "../myStatistics/MyTransaction";
import MyRequests from "../myStatistics/MyRequests";
import StatisticsTable from "../statistics/StatisticsTable";
const token = window.localStorage.getItem("@jsonWepToken");

export const ProfileRouter = () => {
  return (
    <>
      {token ? (
        <Routes>
          <Route exact={true} element={<ProfileNavbar />}>
            <Route path="/profile-info" element={<Profile />} />
            <Route path="/stream-all" element={<StreamAll />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/stream" element={<Stream />} />
            <Route path="/profile-edit" element={<ProfileEdit />} />
            <Route path="/order-state" element={<StatisticsPage />} />
            <Route path="/order-state-table" element={<StatisticsTable />} />
            <Route path="/my-team" element={<MyTeamPage />} />
            <Route path="/competition" element={<Konkurs />} />
            <Route path="/most/seen/product" element={<ViewProductsPage />} />
            <Route
              path="/best-selling/product"
              element={<BestSellingProductPage />}
            />
            <Route
              path="/best-selling/product"
              element={<BestSellingProductPage />}
            />
            <Route path="/my-statistics" element={<MyStatistics />} />
            {/* <Route
              path="/my-stream-statistic"
              element={<MyStreamStatistic />}
            /> */}
            <Route path="/my-transaction" element={<MyTransaction />} />
            <Route path="/my-requests" element={<MyRequests />} />
            <Route path="/stream-chart/:id" element={<ChartStream />} />
          </Route>
        </Routes>
      ) : (
        <Login />
      )}
    </>
  );
};

const ProfileNavbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileInfo());
  }, []);

  function handleLogout() {
    if (window.confirm("Haqiqatan chiqmoqchimisiz ?")) {
      dispatch(logout());
    }
  }

  return (
    <>
      <MHeader>
        <MContainer>
          <div className="profile__page__links">
            <MLink to="/profile/profile-info">
              <div
                className={`${
                  splitLocation[2] === "profile-info" ? "active" : ""
                } profile__link`}
              >
                <AiOutlineHome size={20} />
                <span className="profile__link__text">Asosiy</span>
              </div>
            </MLink>
            <MLink to="/profile/stream-all">
              <div
                className={`${
                  splitLocation[2] === "stream-all" ? "active" : ""
                } profile__link`}
              >
                <GiWaves size={20} />
                <span className="profile__link__text">Market</span>
              </div>
            </MLink>

            <MLink to={"/profile/my-statistics"}>
              <div
                className={`${
                  splitLocation[2] === "my-statistics" ? "active" : ""
                } profile__link`}
              >
                <RiBarChartGroupedLine size={20} />
                <span className="profile__link__text">Statistika</span>
              </div>
            </MLink>

            <MLink to={"/profile/transaction"}>
              <div
                className={`${
                  splitLocation[2] === "transaction" ? "active" : ""
                } profile__link`}
              >
                <BiTransferAlt size={20} />
                <span className="profile__link__text">O'tkazmalar</span>
              </div>
            </MLink>
            <MLink to={"/profile/stream"}>
              <div
                className={`${
                  splitLocation[2] === "stream" ? "active" : ""
                } profile__link`}
              >
                <RiShieldUserLine size={20} />
                <span className="profile__link__text">Oqimlarim</span>
              </div>
            </MLink>
            <div
              className="profile__link"
              style={{ cursor: "pointer" }}
              onClick={() => handleLogout()}
            >
              <AiOutlineLogin size={20} />
              <span className="profile__link__text">Chiqish</span>
            </div>
          </div>
        </MContainer>
      </MHeader>
      <Outlet />
    </>
  );
};

export default Profile;
