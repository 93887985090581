import React from "react";
import { Form, Field } from "react-final-form";
import { MButton } from "../../element/Elemens";

const StreamSubmit = ({ onClickStreamData, product, loading }) => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async (values) => {
    await sleep(300);
    onClickStreamData({ ...values, product_id: product.id });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.desc) {
          errors.desc = {
            required: true,
            text: "Oqim nomini kiriting"
          };
        }
        return errors;
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="stream__card__create_form_row"
          >
            <div>
              <Field name="desc">
                {({ input, meta }) => {
                  return (
                    <div>
                      <input
                        {...input}
                        type="text"
                        placeholder="Masalan kanal nomi kiriting"
                        className="stream__cart__body__input stream__all__input"
                      />
                      <div className="stream__cart__body__error_value">
                        {meta.error && meta.touched && (
                          <span>{meta.error.text}</span>
                        )}
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
            <MButton
              type="submit"
              className="stream__button"
              disabled={loading}
            >
              Oqim yaratish
            </MButton>
          </form>
        );
      }}
    />
  );
};

export default StreamSubmit;
