import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./container/Navbar";
import Product from "./pages/product/Product";
import ProductsImage from "./pages/product/ProductsImage";
import ProductOrder from "./pages/product/ProductOrder";
import { ProfileRouter } from "./pages/profile/ProfileNavbar";
import ScrollTop from "./container/ScrollTop";
import Login from "./pages/login/Login";
import Footer from "./pages/footer/Footer";
import NotFound from "./pages/notFound/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Policy from "./container/Policy";
import MetaPixel from "./pages/facebook/MetaPixel";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <div>
          <ScrollTop />
          <MetaPixel />
          <Navbar />
          <ToastContainer position="top-center" />
          <Routes>
            <Route path="/" exact={true} element={<Product />} />
            <Route
              path="/product/order/:productID"
              element={<ProductOrder />}
            />
            <Route path="/products" element={<ProductsImage />} />
            <Route path="profile/*" element={<ProfileRouter />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/policy" element={<Policy />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
