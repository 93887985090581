import React, { useEffect, useState } from "react";
import { MContainer } from "../../element/Elemens";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { FaTelegram } from "react-icons/fa";
import { VscCopy } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import requests from "../../helpers/requests";
import "../../assets/scss/_stream.scss";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import StreamSubmit from "../../component/Stream/StreamSubmit";
import CurrencyFormat from "react-currency-format";
import { fetchProductsStream } from "../../redux/actions/productActions";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import StreamAllLoading from "../../component/loading/stream/stream_all_loading";
const URLUZTEZ = `${process.env.REACT_APP_API_DOMAIN}`;

const ProductStreamAll = () => {
  const dispatch = useDispatch();
  const [show_url, set_show_url] = useState(false);
  const [url_data, set_url_data] = useState({});
  const onCloseModal = () => set_show_url(false);

  // Create stream clent
  const createStream = (params) => {
    dispatch({ type: "create_stream_start", payload: params });

    requests
      .createStream(params)
      .then(({ data }) => {
        dispatch({ type: "create_stream_success", payload: data });
        set_url_data(data.data);
        set_show_url(true);
      })
      .catch(({ response }) => {
        let message =
          (response && response.data.message) || "Oqimlar topilmadi";
        toast.error(message);

        dispatch({ type: "create_stream_error", payload: message });
      });
  };

  const [filter_stream, set_filter_stream] = useState({
    page: 1,
    per_page: 20
  });

  useEffect(() => {
    dispatch(fetchProductsStream(filter_stream));
  }, []);

  const productStream = useSelector((state) => state.product.list);
  const { loading, meta } = useSelector((state) => state.product);

  useEffect(() => {
    if (meta.current_page) {
      window.scrollTo(0, 0);
    }
  }, [meta.current_page]);

  return (
    <MContainer>
      <h2 className="stream__title">Hozirda sotuvda bor mahsulotlar</h2>
      <div className="stream_search_parent">
        <input
          className="stream_input_search"
          placeholder="Qidirish mahsulot nomi bilan"
          onChange={(e) => {
            let newFilter = {
              ...filter_stream,
              q: e.target.value,
              page: 1
            };

            dispatch(fetchProductsStream(newFilter));
            set_filter_stream(newFilter);
          }}
        />
      </div>
      <div className="stream__grid">
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <StreamAllLoading key={item} />
            ))}
          </>
        ) : (
          <>
            {productStream.map((product, index) => (
              <div key={index} className="stream__cart">
                <div
                  className="stream__cart__image__ramce"
                  onClick={() =>
                    window.open(`${URLUZTEZ}product/order/${product.id}`)
                  }
                >
                  <img
                    src={URLUZTEZ + `uploads/${product.main_image}`}
                    alt="Stream img"
                  />
                </div>
                <div className="stream__cart__body">
                  <h6 className="stream__cart__body__title">{product.title}</h6>

                  <h5 className="stream__cart__body__price">
                    Narxi:{" "}
                    <CurrencyFormat
                      value={product.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    so'm
                  </h5>
                  <h4 className="stream__cart__body__price">
                    Oqim narxi:{" "}
                    <CurrencyFormat
                      value={product.stream_price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />{" "}
                    so'm
                  </h4>

                  <h5
                    className="stream__cart__body__telegram_link"
                    onClick={() => window.open(`${product.telegram_link}`)}
                  >
                    Reklama posti
                  </h5>

                  <StreamSubmit
                    product={product}
                    loading={loading}
                    onClickStreamData={(streamData) => {
                      createStream(streamData);
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <ResponsivePagination
        current={meta.current_page}
        total={meta.last_page}
        onPageChange={(e) => {
          let newFilter = {
            ...filter_stream,
            page: e,
            per_page: 20
          };

          dispatch(fetchProductsStream(newFilter));
          set_filter_stream(newFilter);
        }}
      />
      <Modal
        open={show_url}
        onClose={onCloseModal}
        className="stream_responsive_modal_modal"
        aria-labelledby="my-modal-title"
        blockScroll={false}
        animationDuration={400}
        closeIcon={<IoMdClose style={{ color: "white" }} size={20} />}
      >
        <h3 id="my-modal-title">Oqim yaratildi</h3>
        <div className="stream__modal__body">
          <div className="stream__modal__form__group">
            <input
              className="stream__modal__input"
              defaultValue={URLUZTEZ + `stream/${url_data.stream_id}`}
              disabled
            />
          </div>
          <div className="stream__modal__button__box">
            <button
              className="stream__modal__button stream__modal__button__left"
              onClick={() => {
                window.open(`${url_data.telegram_link}`);
              }}
            >
              Telegram post <FaTelegram className="_icon" />
            </button>
            <button
              className="stream__modal__button"
              onClick={() => {
                navigator.clipboard.writeText(
                  URLUZTEZ + `stream/${url_data.stream_id}`
                );
                toast.success("Oqimdan nusxa olindi!");
              }}
            >
              Copy <VscCopy className="_icon" />
            </button>
          </div>
        </div>
      </Modal>
    </MContainer>
  );
};

export default ProductStreamAll;
