import React, { useState } from "react";
import { FaWalking } from "react-icons/fa";
import { FiAnchor } from "react-icons/fi";
import { RiSpam3Line } from "react-icons/ri";
import { SiSymantec } from "react-icons/si";
import { AiOutlineHeatMap } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { MdClearAll, MdPhoneMissed, MdOutlineWavingHand } from "react-icons/md";
import { IoIosSubway, IoMdArchive } from "react-icons/io";
import "../../assets/scss/_order.scss";
import UserRequestModal from "./UserRequestModal";

const ProfileOrdersInfo = ({ statisticsList }) => {
  const [show_modal, set_show_modal] = useState(false);
  const [order_status, set_order_status] = useState(null);

  const statusSwitch = (status) => {
    switch (status) {
      case "visits":
        return (
          <div className="profile__cart__order__title">
            <h3>Tashrif (Barchasi)</h3>
            <FaWalking size={20} />
          </div>
        );
      case "new":
        return (
          <div className="profile__cart__order__title">
            <h3>Yangi buyurtma</h3>
            <MdOutlineWavingHand size={20} />
          </div>
        );
      case "accepted":
        return (
          <div className="profile__cart__order__title">
            <h3>Dostavkaga tayyor</h3>
            <SiSymantec size={20} />
          </div>
        );
      case "delivered":
        return (
          <div className="profile__cart__order__title">
            <h3>Yetkazildi</h3>
            <SiSymantec size={20} />
          </div>
        );
      case "delivered":
        return (
          <div className="profile__cart__order__title">
            <h3>Yetkazildi</h3>
            <SiSymantec size={20} />
          </div>
        );
      case "temporary":
        return (
          <div className="profile__cart__order__title">
            <h3>Taqsimlanganlar</h3>
            <AiOutlineHeatMap size={20} />
          </div>
        );
      case "archieved":
        return (
          <div className="profile__cart__order__title">
            <h3>Arxivlandi</h3>
            <IoMdArchive size={20} />
          </div>
        );
      case "pending":
        return (
          <div className="profile__cart__order__title">
            <h3>Qayta qo'ngiroq</h3>
            <MdPhoneMissed size={20} />
          </div>
        );
      case "spam":
        return (
          <div className="profile__cart__order__title">
            <h3>Chorni spiska</h3>
            <RiSpam3Line size={20} />
          </div>
        );
      case "hold":
        return (
          <div className="profile__cart__order__title">
            <h3>Hold</h3>
            <FiAnchor size={20} />
          </div>
        );
      case "wrong":
        return (
          <div className="profile__cart__order__title">
            <h3>Nosoz</h3>
            <FiAnchor size={20} />
          </div>
        );
      case "cancelled":
        return (
          <div className="profile__cart__order__title">
            <h3>Otkazlar</h3>
            <TiCancel size={25} />
          </div>
        );
      case "sent":
        return (
          <div className="profile__cart__order__title">
            <h3>Yetkazilmoqda</h3>
            <IoIosSubway size={20} />
          </div>
        );
      case "all":
        return (
          <div className="profile__cart__order__title">
            <h3>Barchasi</h3>
            <MdClearAll size={25} />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="grid__order__information">
      {statisticsList.map((statistic, index) => {
        return (
          <div
            className={`profile__cart__order order_${statistic.label}`}
            key={index}
            onClick={() => {
              set_order_status(statistic.label);
              set_show_modal(true);
            }}
          >
            <div className="profile__cart__order__body">
              {statusSwitch(statistic.label)}
              <h4 className="profile__cart__number__of__order">
                {statistic.count} ta
              </h4>
            </div>
          </div>
        );
      })}
      {order_status && (
        <UserRequestModal
          show_modal={show_modal}
          order_status={order_status}
          onCloseModal={() => {
            set_show_modal(false);
            set_order_status(null);
          }}
        />
      )}
    </div>
  );
};

export default ProfileOrdersInfo;
