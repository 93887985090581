import React from "react";
import { MContainer, MFooter, MLink } from "../../element/Elemens";
import "../../assets/scss/_footer.scss";
import { FaTelegramPlane, FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import appsote from "../../assets/images/appstorepng.png";

const Footer = () => {
  return (
    <MFooter>
      <MContainer>
        <div className="footer__body">
          <div className="footer__grid">
            <h2 className="footer__logo">UzTez</h2>
            <div className="mobile_none">
              <h4 className="footer__item__tetli">Biz bilan ishlab ko'ring</h4>
              <h5 className="footer__item">Ishonchingiz zoyi kitmaydi</h5>
              <h5 className="footer__item">2022-01-01</h5>
              <h5 className="footer__item">uztezuz@gmail.com</h5>
            </div>
            <div>
              <h4 className="footer__item__tetli">Ishlab ko'rmoqchimisiz?</h4>
              <MLink to={"/profile/profile-info"}>
                <h5 className="footer__item">Albatta ro'yhatdan o'ting</h5>
              </MLink>
              <MLink to={"/profile/stream-all"}>
                <h5 className="footer__item">
                  Hoziroq ish boshlashingiz mumkun
                </h5>
              </MLink>
            </div>
            <div>
              <h4 className="footer__item__tetli">Bizning aloqa</h4>
              <div className="footer__item__icon">
                <FaTelegramPlane
                  size={20}
                  className="item__icon"
                  onClick={() =>
                    window.open("https://telegram.me/uztez_market_admini")
                  }
                />
                <BsInstagram
                  size={20}
                  className="item__icon"
                  onClick={() =>
                    window.open("http://instagram.com/_u/uztez_uz/")
                  }
                />
                <FaFacebookF size={20} className="item__icon" />
              </div>

              <MLink to="/policy">
                <h5 className="footer__item">Maxfiylik siyosati</h5>
              </MLink>
            </div>
            <div className="footer__img__store__phone">
              <h4 className="footer__item__tetli">Biz bilan ishlab ko'ring</h4>
              <h5 className="footer__item__img">
                <img src={appsote} alt="App Store" />
              </h5>
            </div>
          </div>
        </div>
      </MContainer>
    </MFooter>
  );
};

export default Footer;
