import React, { useEffect } from "react";
import "../../assets/scss/_view_product.scss";
import CanvasJSReact from "../../assets/chart/canvasjs.react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductWiew } from "../../redux/actions/chartActions";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ViewProductsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductWiew());
  }, []);

  const viewProducts = useSelector((state) => state.chart.list_view);

  const listView = viewProducts.map((item) => {
    return {
      y: item.total,
      label: item.id
    };
  });

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1",
    title: {
      text: "Eng ko'p ko'rilgan mahsulotlar!"
    },
    data: [
      {
        type: "pie",
        indexLabel: "{label}: {y}%",
        startAngle: -90,
        dataPoints: listView
      }
    ]
  };

  return <CanvasJSChart options={options} />;
};

export default ViewProductsPage;
