import React, { useEffect } from "react";
import { MContainer } from "../../element/Elemens";
import "../../assets/scss/_my_statistics.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { orderChartStreamState } from "../../redux/actions/orderActions";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Oqim",
    selector: "stream_id",
    sortable: true
  },
  {
    name: "Yangi",
    selector: "createdAt",
    sortable: true
  },
  {
    name: "Dostavkaga tayyor",
    selector: "customer_name",
    sortable: true
  },
  {
    name: "",
    selector: "customer_phone",
    sortable: true
  },
  {
    name: "Narx",
    selector: "quantity",
    sortable: true
  },
  {
    name: "Tafsif",
    selector: "description",
    sortable: true
  },
  {
    name: "Holat",
    selector: "status",
    sortable: true
  }
];

const MyStreamStatistic = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(orderChartStreamState(id));
  }, []);

  const orderList = useSelector((state) => state.orders.list);

  return (
    <MContainer>
      <div className="not__font_mu">
        Hozirda texnik ishlar olib borilmoqda 🙂👍
      </div>
      {/* <DataTable
        columns={columns}
        data={orderList}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      /> */}
    </MContainer>
  );
};

export default MyStreamStatistic;
