const INITIAL_STATE = {
  list: [],
  loading: false,
  message: null,
  sidebarShow: "responsive",
  data: {},
  orderSaved: 0,
  hasMore: true,
  loadingOrder: false,
  meta: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_products_start":
      return { ...state, loading: true, message: "" };
    case "fetch_products_error":
      return { ...state, message: payload, loading: false };
    case "fetch_products_success":
      if (!payload.data.length || !payload.meta) {
        return {
          ...state,
          loading: false,
          list: []
        };
      }

      if (payload.meta.current_page > 1) {
        return {
          ...state,
          loading: false,
          list: [...state.list, ...payload.data],
          hasMore: payload.meta.current_page < payload.meta.last_page
        };
      }

      return {
        ...state,
        loading: false,
        list: payload.data,
        hasMore: payload.meta.current_page < payload.meta.last_page
      };

    // all products stream
    case "fetch_products_stream_start":
      return { ...state, loading: true, message: "" };
    case "fetch_products_stream_error":
      return { ...state, message: payload, loading: false };
    case "fetch_products_stream_success":
      return {
        ...state,
        loading: false,
        list: payload.data,
        meta: payload.meta
      };

    // one product
    case "fetch_one_product_start":
      return { ...state, loadingOrder: true, message: "" };
    case "fetch_one_product_error":
      return { ...state, message: payload, loadingOrder: false };
    case "fetch_one_product_success":
      return {
        ...state,
        message: payload.message,
        loadingOrder: false,
        data: payload.data
      };

    /* other */
    default:
      return state;
  }
};
