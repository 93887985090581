import { toast } from "react-toastify";
import requests from "../../helpers/requests";

// Clent stream
export const createPaymentRequest = (params) => (dispatch) => {
  dispatch({ type: "create_transaction_start", payload: params });

  requests
    .createPaymentRequest(params)
    .then(({ data }) => {
      dispatch({ type: "create_transaction_success", payload: data });
      toast.success("Mufaqayatli qo'shildi, yaqin orada ko'rib chiqiladi");
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Pul o'tkamazma xatolik bor";
      toast.error(
        message ||
          "Pul o'tkamazma xatolik bor iltimos hammasi tug'riligiga etibor bering"
      );

      dispatch({ type: "create_transaction_error", payload: message });
    });
};

// Clent stream
export const fetchPaymentRequest = () => (dispatch) => {
  dispatch({ type: "fetch_payment_request_start" });

  requests
    .fetchPaymentRequest()
    .then(({ data }) => {
      dispatch({ type: "fetch_payment_request_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening tranzaksiyam topilmadi";

      dispatch({ type: "fetch_payment_request_error", payload: message });
    });
};

// Clent stream
export const fetchTransactions = (params) => (dispatch) => {
  dispatch({ type: "fetch_transactions_start" });

  requests
    .fetchTransactions(params)
    .then(({ data }) => {
      dispatch({ type: "fetch_transactions_success", payload: data });
    })
    .catch(({ response }) => {
      let message =
        (response && response.data.message) || "Mening tranzaksiyam topilmadi";

      dispatch({ type: "fetch_transactions_error", payload: message });
    });
};
