import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import KonkursImg from "../../assets/images/kon.jpg";
import "../../assets/scss/_profile.scss";
import { MContainer } from "../../element/Elemens";
import {
  fetchContestJoin,
  fetchContestParticipants
} from "../../redux/actions/userActions";

const Konkurs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContestParticipants());
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row) => <div>{row.wrong_way}-o'rin</div>
    },
    {
      name: "Sotuvchilar",
      cell: (row) => <div>{row.last_name}</div>
    },
    {
      name: "Sotgan mahsuloti",
      cell: (row) => (
        <div style={{ fontWeight: "bold" }}>{row.contest_orders} ta</div>
      )
    }
  ];

  const userInfo = useSelector((state) => state.user.userInfo);
  const happeningList = useSelector((state) => state.user.list);

  return (
    <MContainer>
      <div className="competition__page__name_">
        Hozircha konkurs tuxtatilgan!
      </div>
    </MContainer>
    // <MContainer>
    //   <div className="competition__page">
    //     {/* <div>
    //       <img src={KonkursImg} className="competition__image__container" />
    //     </div> */}
    //     <div className="competition__page__body">
    //       <div className="competition__page__title">
    //         Uztez.uz jamaosi o’zining super konkursini e’lon qiladi
    //       </div>
    //       Uztez.uz jamoasi o'z xamkorlarini kuttirishni istamaydi va shu sababli
    //       ham jamoa 2023 yilning 26 dekabr sanasida navbatdagi super konkursiga
    //       start beradi. Barcha xamkorlarimiz ushbu konkursda qatnashish
    //       imkoniyatiga ega. Konkursda qatnashish orqali o'z shaxsiy
    //       daromadlarini ko'paytirishi va qo'shimchasiga qimmatbaxo so'vrinlarni
    //       qo'lga kiritishlari mumkin. Shartlari esa juda oddiy :  konkurs 2023
    //       yilning 26 dekabr sanasidan 2024 yilning 31 aprel sanasichagacha davom
    //       etadi va aynan shu vaqt oralig'ida siz  uztez.uz maxsulotlarini
    //       maksimal darajada ko'proq sotish orqali eng qimmatbaxo so'vrinlarni
    //       qo'lga kiritish imkoniyatiga ega bo'lasiz.
    //       {/* <div className="competition__page__text">
    //         Aynan siz online savdo ishlarini uztez.uz sayti bilan xamkorlikda
    //         olib borasizmi? Unday bo’lsa ushbu konkurs aynan siz uchun. Konkurs
    //         orqali o’z shaxsiy daromadingizni ko’paytiring va qo’shimchasiga
    //         qimmatbaxo so’vrinlarni qo’lga kiriting. Ushbu super konkurs{" "}
    //         <span className="date_time_start">8 sentyabrdan</span>{" "}
    //         <span className="date_time_end">18 noyabrgacha</span> davom etadi.
    //         Shartlari esa juda oddiy va osson:{" "}
    //         <span className="date_time_hour">
    //           70 kun mobaynida ko’proq maxsulot soting va sovrinlarni qo’lga
    //           kiriting.
    //         </span>
    //       </div> */}
    //       <div className="competition__page__gift">
    //         <div className="competition__page__gift__title">
    //           So’vrinlarimiz:
    //         </div>
    //         <div className="competition__page__gift__d">
    //           1-o’ringa – 2 pozitsiya Nexia 3 avtomobili YOKI 90,000,000 so'm
    //           PUL MIQDORI -{" "}
    //           <span className="competition__page__gift__amount">18.000 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           2-o’ringa – 1 pozitsiya Spark avtomobili YOKI 75,000,000 PUL
    //           MIQDORI -{" "}
    //           <span className="competition__page__gift__amount">15.000 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           3-o’ringa – Holati a'lo bo'lgan Matiz avtomobili YOKI 50,000,000
    //           PUL MIQDORI -{" "}
    //           <span className="competition__page__gift__amount">10.000 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           4-o’ringa – Umra safariga 2 kishilik yo'llanma -{" "}
    //           <span className="competition__page__gift__amount">5.000 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           5-o’ringa – Dubayga 2 kishilik sayohat -{" "}
    //           <span className="competition__page__gift__amount">4.000 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           6-o’ringa – Umra safariga 1 kishilik yo'llanma -{" "}
    //           <span className="competition__page__gift__amount">2.500 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           7-o’ringa – iPhone 14 Pro max telefoni -{" "}
    //           <span className="competition__page__gift__amount">2.400 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           8-o’ringa – Dubayga 1 kishilik sayohat -{" "}
    //           <span className="competition__page__gift__amount">2.200 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           9-o’ringa – Samsung S22 ultra telefoni -{" "}
    //           <span className="competition__page__gift__amount">1.650 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           10-o’ringa – Qatarga bilet (6,000,000 so'mgacha) -{" "}
    //           <span className="competition__page__gift__amount">1.500 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           11-o’ringa – Muzlatgich -{" "}
    //           <span className="competition__page__gift__amount">700 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           12-o’ringa – SAMSUNG S21 TELEFON -{" "}
    //           <span className="competition__page__gift__amount">600 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           13-o’ringa – SAMSUNG A14 TELEFON -{" "}
    //           <span className="competition__page__gift__amount">500 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           14-o’ringa – REDMI C12 TELEFON -{" "}
    //           <span className="competition__page__gift__amount">350 ta</span>
    //         </div>
    //         <div className="competition__page__gift__d">
    //           15-o’ringa – REDMI A9 TELEFON -{" "}
    //           <span className="competition__page__gift__amount">300ta</span>
    //         </div>
    //       </div>
    //       <div className="competition__page__footer">
    //         Konkurs 31 - aprel kunigacha davom etadi!
    //       </div>
    //       <div className="competition__page__footer">
    //         Konkurs tugaganidan so’ng sov’galar 20 kun ichida o’z egalariga
    //         topshiriladi!
    //       </div>
    //       <div className="competition__page__footer">
    //         Xar bir sotilgan mahsulot konkursning dinariga qo'shilib boriladi.
    //         Konkurs davrida yig'ilgan dinarlar tugatilgandan so'ng ayirib
    //         yuboriladi
    //       </div>
    //     </div>
    //     {userInfo.attending_contest ? (
    //       ""
    //     ) : (
    //       <div
    //         className="competition__page__footer__button"
    //         onClick={() => {
    //           dispatch(fetchContestJoin());
    //         }}
    //       >
    //         Konkursga start berish
    //       </div>
    //     )}

    //     <div>
    //       <DataTable
    //         columns={columns}
    //         data={happeningList}
    //         responsive
    //         noHeader
    //         defaultSortAsc={false}
    //       />
    //     </div>
    //   </div>
    // </MContainer>
  );
};

export default Konkurs;
