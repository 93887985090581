import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileOrdersInfo from "./UserOrderStatistics";
import { MContainer, MLink } from "../../element/Elemens";
import { fetchStreamStatistic } from "../../redux/actions/streamActions";
import "../../assets/scss/_profile.scss";
import { OrderStatusLoading } from "../../component/loading/userOrderStatus/orderStatusLoading";

const Statistics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStreamStatistic());
  }, []);

  const statisticList = useSelector((state) => state.stream.list);
  const { loading } = useSelector((state) => state.stream);

  return (
    <MContainer>
      <MLink to="/profile/order-state-table">
        <div className="statistic_title_button">Jadval ko'rinishiga</div>
      </MLink>
      {loading ? (
        <OrderStatusLoading />
      ) : (
        <ProfileOrdersInfo statisticsList={statisticList} />
      )}
    </MContainer>
  );
};

export default Statistics;
