import React from "react";
import "../index.css";

const Policy = () => {
  return (
    <div className="policy_style">
      <div style={{ textAlign: "center", margin: "10px 0px" }}>
        "UZTEZ" maxfiylik siyosati
      </div>
      Shaxsiy ma’lumotlarni qayta ishlash siyosati
      <br />
      1. Umumiy qoidalar Shaxsiy ma’lumotlarni qayta ishlash bo‘yicha ushbu
      siyosat O‘zbekiston Respublikasining 02.07.2019 yildagi O‘RQ-547-sonli
      «Shaxsga doir ma’lumotlar to‘g‘risida»gi qonuni talablariga muvofiq ishlab
      chiqilgan hamda «Uztez Market» MChJ (keyingi o‘rinlarda – «Operator»)
      tomonidan shaxsiy ma’lumotlarini qayta ishlash tartibini va shaxsiy
      ma’lumotlarining xavfsizligini ta’minlash choralarini belgilaydi.
      Operatorning shaxsiy ma’lumotlarni qayta ishlashga oid ushbu siyosati
      (keyingi o‘rinlarda – Siyosat) Operator tomonidan https://uztez.uz
      Internet tarmog’idagi veb-saytiga (keyingi o‘rinlarda – Sayt) tashrif
      buyuruvchilar haqida olinishi mumkin bo‘lgan barcha ma’lumotlarga nisbatan
      qo‘llaniladi. Ushbu siyosat to'g'ridan-to'g'ri Saytga va uning yordamida
      olinadigan ma'lumotlarga nisbatan qo'llaniladi. Siyosat boshqa saytlarga
      taalluqli emas va sayt haqida eslatmani o'z ichiga olishi mumkin bo'lgan
      va saytga havolalar berilishi mumkin bo'lgan uchinchi tomon
      veb-saytlariga, shuningdek Saytdan boshqa Internet tarmog’idagi
      veb-saytlarga havolalar uchun amal qilmaydi.
      <br />
      2. Siyosatda ishlatiladigan asosiy tushunchalar
      <br />
      2.1. Shaxsiy ma’lumotlarni avtomatlashtirilgan tarzda qayta ishlash –
      shaxsiy ma’lumotlarni hisoblash texnikasi vositalari yordamida qayta
      ishlash;
      <br />
      2.2. Shaxsiy ma’lumotlarni blokirovka qilish – shaxsiy ma’lumotlarni qayta
      ishlashni vaqtincha to‘xtatish (qayta ishlash shaxsiy ma’lumotlarni
      aniqlashtirish uchun zarur bo‘lgan holatlardan tashqari);
      <br />
      2.3. Shaxsiy ma’lumotlarning axborot tizimi – ma’lumotlar bazalarida
      mavjud bo‘lgan shaxsiy ma’lumotlar va ularning qayta ishlanishini
      ta’minlovchi axborot texnologiyalari va texnik vositalar to‘plami;
      <br />
      2.4. Shaxsiy ma’lumotlarni egasizlashtirish – qo‘shimcha ma’lumotlardan
      foydalanmasdan shaxsiy ma’lumotlarning muayyan Foydalanuvchi yoki boshqa
      shaxsiy ma’lumotlar sub’yektiga tegishli ekanligini aniqlash imkonini
      bartaraf qiladigan harakatlar;
      <br />
      2.5. Shaxsiy ma’lumotlarni qayta ishlash – avtomatlashtirish vositalaridan
      foydalangan holda yoki bunday vositalardan foydalanmasdan shaxsiy
      ma’lumotlar bilan bajariladigan har qanday harakat (operatsiya) yoki
      harakatlar (operatsiyalar) majmui, shu jumladan shaxsiy ma’lumotlarni
      yig‘ish, yozib olish, tizimlashtirish, to‘plash, saqlash, aniqlashtirish
      (yangilash, o‘zgartirish), olish, foydalanish, uzatish (tarqatish, taqdim
      etish, kirish), egasizlashtirish, blokirovka qilish, o‘chirish, yo‘q
      qilish;
      <br />
      2.6. Shaxsiy ma’lumotlar – https://uztez.uz Internet tarmog’idagi
      veb-saytining foydalanuvchisiga bevosita yoki bilvosita aloqador har
      qanday ma’lumot;
      <br />
      2.7. Foydalanuvchi – https://uztez.uz Internet tarmog’idagi veb-saytiga
      barcha tashrif buyuruvchilar;
      <br />
      2.8. Shaxsiy ma’lumotlarni taqdim etish – shaxsiy ma’lumotlarni muayyan
      shaxsga yoki muayyan doiradagi shaxslarga oshkor qilishga qaratilgan
      harakatlar;
      <br />
      2.9. Shaxsiy ma’lumotlarni tarqatish – shaxsiy ma’lumotlarni noaniq
      doiradagi shaxslarga oshkor etishga (shaxsiy ma’lumotlarni uzatishga) yoki
      cheklanmagan doiradagi shaxslarni shaxsiy ma’lumotlar bilan tanishtirishga
      qaratilgan harakatlar, shu jumladan ommaviy axborot vositalarida shaxsiy
      ma’lumotlarni e’lon qilish, axborot va telekommunikatsiya tarmoqlarida
      joylashtirish yoki boshqa har qanday yo‘l bilan ularni olish imkoniyatini
      berish;
      <br />
      2.10. Shaxsiy ma’lumotlarni yo‘q qilish – shaxsiy ma’lumotlar axborot
      tizimida keyinchalik shaxsiy ma’lumotlar mazmunini tiklash imkoniyati
      bo‘lmagan holda shaxsiy ma’lumotlarni qaytarib bo‘lmaydigan tarzda yo‘q
      qiladigan va (yoki) moddiy shaxsiy ma’lumotlar tashuvchilarini yo‘q
      qiladigan har qanday harakatlar.
      <br />
      3. Operator Foydalanuvchining quyidagi shaxsiy ma’lumotlarini qayta
      ishlashi mumkin 1. FIO; 2. Firma nomi; 3. Telefon raqamlari; 4. Elektron
      manzil; 5. Rezyume, shuningdek unda mavjud bo'lgan barcha ma'lumotlar; 6.
      Shuningdek, tashrif buyuruvchilar to‘g‘risidagi egasizlashtirilgan
      ma’lumotlar (shu jumladan “cookie” fayllar) to‘planadi va qayta ishlanadi.
      <br />
      4. Shaxsiy ma’lumotlarni qayta ishlash maqsadlari
      <br />
      4.1. Foydalanuvchi shaxsiy ma’lumotlarini qayta ishlashning maqsadi –
      Foydalanuvchini SMS va push xabarlar yuborish orqali xabardor qilish;
      Foydalanuvchiga veb-saytdagi mavjud xizmatlarga, ma’lumotlarga va/yoki
      materiallarga kirish huquqini berish; talabnoma asosida teskari aloqani
      taqdim etish.
      <br />
      4.2. Operator, shuningdek, Foydalanuvchiga yangi mahsulotlar va xizmatlar,
      maxsus takliflar va turli hodisalar to‘g‘risida xabarlarni yuborish
      huquqiga ega. Foydalanuvchi har doim Operatorga info@uztez.uz elektron
      manzil orqali «Yangi mahsulotlar va xizmatlar va maxsus takliflar haqidagi
      xabarlardan voz kechish» belgisiga ega murojaatni yuborish orqali ma’lumot
      olishdan voz kechishi mumkin.
      <br />
      4.3. Foydalanuvchilarning internet-statistika xizmatlari yordamida
      yig‘iladigan egasizlashtirilgan ma’lumotlari Foydalanuvchilarning saytdagi
      xatti-harakatlari to‘g‘risida ma’lumot to‘plash, saytning sifati va uning
      mazmunini yaxshilash uchun xizmat qiladi.
      <br />
      5. Shaxsiy ma’lumotlarni qayta ishlashning asoslari
      <br />
      5.1. Operator Foydalanuvchining shaxsiy ma’lumotlarini, faqat ular
      Foydalanuvchi tomonidan mustaqil ravishda http://uztez.uz Internet
      tarmog’idagi veb-saytida joylashtirilgan maxsus shakl «Qayta aloqa»
      vositasida to‘ldirilganda va/yoki yuborilgandagina qayta ishlaydi.
      Foydalanuvchi tegishli shakllarni to‘ldirish va/yoki Operatorga o‘z
      shaxsiy ma’lumotlarini yuborish orqali ushbu Siyosatga o‘z roziligini
      bildiradi.
      <br />
      5.2. Operator Foydalanuvchi haqidagi egasizlashtirilgan ma’lumotlarni,
      agar Foydalanuvchi brauzerining sozlamalarida bunga ruxsat etilgan
      (“cookie” fayllarni saqlash va JavaScript texnologiyalaridan foydalanish
      yoqilgan) bo‘lsa qayta ishlaydi.
      <br />
      6. Shaxsiy ma’lumotlarni to‘plash, saqlash, uzatish va boshqa turda qayta
      ishlash tartibi
      <br />
      6.1. Operator tomonidan qayta ishlanadigan shaxsiy ma’lumotlarning
      Xavfsizligi shaxsiy ma’lumotlarni himoya qilish sohasidagi amaldagi qonun
      hujjatlari talablariga to‘liq rioya qilish uchun zarur bo‘lgan huquqiy,
      tashkiliy va texnik chora-tadbirlarni amalga oshirish orqali ta’minlanadi.
      <br />
      6.2. Operator shaxsiy ma’lumotlarning saqlanishini ta’minlaydi va vakolat
      berilmagan shaxslarning shaxsiy ma’lumotlarga kirishini bartaraf qilish
      uchun barcha mumkin bo‘lgan choralarni ko‘radi.
      <br />
      6.3. Foydalanuvchining shaxsiy ma’lumotlari hech qachon, hech qanday
      holatda uchinchi shaxslarga berilmaydi, amaldagi qonunlarni bajarish bilan
      bog‘liq hollar bundan mustasno.
      <br />
      6.4. Shaxsiy ma’lumotlarda noaniqliklar aniqlanganda, Foydalanuvchi
      Operatorga info@uztez.uz elektron manzil orqali «Shaxsiy ma’lumotlarni
      yangilash» belgisiga ega xabarni yuborish orqali ularni mustaqil ravishda
      yangilashi mumkin.
      <br />
      6.5. Shaxsiy ma’lumotlarni qayta ishlash muddati cheklanmagan.
      Foydalanuvchi istalgan vaqtda Operatorga info@uztez.uz elektron manzil
      orqali «Shaxsiy ma’lumotlarni qayta ishlashga rozilikni bekor qilish»
      belgisiga ega xabarni yuborish orqali shaxsiy ma’lumotlarining qayta
      ishlanishiga o‘z roziligini qaytarib olishi mumkin.
      <br />
      7. Nizolarni hal qilish
      <br />
      7.1. Operator va Foydalanuvchi o'rtasidagi munosabatlardan kelib
      chiqadigan nizolar bo'yicha sudga murojaat qilishdan oldin, da'vo tartibi
      qo’llanilishi (nizoni kelishuv taribida hal qilish bo'yicha yozma taklif)
      majburiydir.
      <br />
      7.2. Kelishuvga erishilmagan taqdirda, nizo O'zbekiston Respublikasining
      amaldagi qonunchiligiga muvofiq sudda ko’rib chiqilishga yuboriladi. 7.3.
      Ushbu Maxfiylik siyosatiga va Operator va Foydalanuvchi o'rtasidagi
      munosabatlarga nisbatan O'zbekiston Respublikasining amaldagi qonunchiligi
      qo'llaniladi.
      <br />
      8. Yakuniy qoidalar
      <br />
      8.1. Operator Foydalanuvchi roziligisiz ushbu Siyosatga o'zgartirishlar
      kiritishga haqlidir.
      <br />
      8.2. Foydalanuvchi info@uztez.uz elektron manzil orqali Operatorga
      murojaat qilib, o‘z shaxsiy ma’lumotlarini qayta ishlashga aloqador barcha
      qiziqtiruvchi savollar bo‘yicha kerakli izohlarni olishi mumkin.
      <br />
      8.3. Ushbu hujjatda Operator tomonidan shaxsiy ma’lumotlarni qayta ishlash
      siyosatidagi har qanday o‘zgarishlar aks ettiriladi. Siyosat yangi tahriri
      bilan almashtirilgunga qadar muddatsiz amal qiladi.
    </div>
  );
};

export default Policy;
