import React, { useEffect, useState } from "react";
import { MContainer, MLink } from "../../element/Elemens";
import "../../assets/scss/_product.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../component/loading/page/LoadingPage";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchProducts } from "../../redux/actions/productActions";
import SearchProductComponent from "../../component/Product/SearchProductComponent";
const URLUztez = `${process.env.REACT_APP_API_DOMAIN}`;

const ProductsImage = () => {
  const dispatch = useDispatch();
  const [productFilter, setProductFilter] = useState({ page: 1, per_page: 20 });

  useEffect(() => {
    dispatch(fetchProducts(productFilter));
  }, []);

  const productList = useSelector((state) => state.product.list);
  const { loading, hasMore } = useSelector((state) => state.product);

  function fetchMore() {
    let newFilterProduct = {
      ...productFilter,
      page: productFilter.page + 1
    };
    setProductFilter(newFilterProduct);
    dispatch(fetchProducts(newFilterProduct));
  }

  return (
    <MContainer>
      <div className="product__image__page">
        <SearchProductComponent
          onChangeSearchProduct={(search) => {
            console.log(search);
          }}
        />
        <InfiniteScroll
          dataLength={productList.length}
          next={fetchMore}
          hasMore={hasMore}
          className="product__image__page__grid"
        >
          {productList.map((product, index) => (
            <MLink
              to={`/product/order/` + product.id}
              key={index}
              className="product__image__page__box"
            >
              <div className="product__image__page__image__ramci">
                <img
                  src={URLUztez + `uploads/${product.main_image}`}
                  alt="Product"
                />
              </div>
              <div className="product__image__page__product__price">
                {product.price} so'm
              </div>
            </MLink>
          ))}
        </InfiniteScroll>
      </div>
      {loading && <LoadingPage />}
    </MContainer>
  );
};

export default ProductsImage;
