import React from "react";
import "../../../assets/scss/_loading.scss";

const LoadingPage = () => {
  return (
    <div className="loading__page">
      <div className="loading__text">Loading...</div>
    </div>
  );
};

export default LoadingPage;
