import React from "react";
import { Skeleton } from "./Skeleton";
import "../../../assets/scss/_order.scss";

export const OrderStatusLoading = () => {
  return (
    <div className="modal__content__cart__loading">
      <div className="modal__content__cart__header__loading_parent">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="modal__content__cart__header__loading_parent2">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="modal__content__cart__header__loading_parent3">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="modal__content__cart__header__loading_parent4">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="modal__content__cart__header__loading_parent5">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
      <div className="modal__content__cart__header__loading_parent6">
        <Skeleton type="modal__content__cart__header__loading" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
    </div>
  );
};
