import React, { useEffect, useState } from "react";
import "../../assets/scss/_my_statistics.scss";
import { MContainer } from "../../element/Elemens";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgentOrders,
  updateOrderNew
} from "../../redux/actions/orderActions";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment/locale/uz-latn.js";
moment.locale("uz-latn");

function statusName(status) {
  switch (status) {
    case "new":
      return (
        <div className="statistic_table_status_badge" id="new">
          "Yangi"
        </div>
      );
    case "accepted":
      return (
        <div className="statistic_table_status_badge" id="accepted">
          "Dostavkaga tayyor"
        </div>
      );
    case "sent":
      return (
        <div className="statistic_table_status_badge" id="sent">
          "Yetkazilmoqda"
        </div>
      );
    case "temporary":
      return (
        <div className="statistic_table_status_badge" id="temporary">
          "Taqsimlanganlar"
        </div>
      );
    case "delivered":
      return (
        <div className="statistic_table_status_badge" id="delivered">
          "Yetkazildi"
        </div>
      );
    case "pending":
      return (
        <div className="statistic_table_status_badge" id="pending">
          "Kiyin oladi"
        </div>
      );
    case "cancelled":
      return (
        <div className="statistic_table_status_badge" id="cancelled">
          "Qaytib kildi"
        </div>
      );
    case "archieved":
      return (
        <div className="statistic_table_status_badge" id="archieved">
          "Arxivlandi"
        </div>
      );
    case "spam":
      return (
        <div className="statistic_table_status_badge" id="spam">
          "Chorni spiska"
        </div>
      );
    case "hold":
      return (
        <div className="statistic_table_status_badge" id="hold">
          "Mahsulot kutilmoqda"
        </div>
      );

    default:
      break;
  }
}

const StatisticsTable = () => {
  const dispatch = useDispatch();
  const [active_class, set_active_class] = useState("all");
  const [filter, setFilter] = useState({
    status: "all",
    page: 1,
    per_page: 10
  });

  useEffect(() => {
    dispatch(fetchAgentOrders(filter));
  }, []);

  const columns = [
    {
      name: "Id/Operator",
      cell: (row) => (
        <div>
          id: {row.order_id} <br /> operator: {row.operator?.name}
        </div>
      )
    },
    {
      name: "Mahsulot",
      cell: (row) => (
        <div>
          {row.product?.title} <br />
          <CurrencyFormat
            value={row.product?.price || 0}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          so'm
        </div>
      )
    },
    {
      name: "Mijoz/Manzil",
      cell: (row) => (
        <div>
          {row.client?.full_name} <br /> +{row.client?.phone_number} <br />{" "}
          {row.region?.title}
        </div>
      )
    },
    {
      name: "Holat",
      cell: (row) => <div>{statusName(row.status)}</div>
    },
    {
      name: "Izoh",
      selector: "comment"
    },
    {
      name: "Sana",
      cell: (row) => (
        <div>
          {moment(row.created_at).fromNow(true)} <br />
          {row.status === "pending" ? (
            <div
              className="statistic_table_button_update_new"
              onClick={() => {
                dispatch(updateOrderNew(row.id));
              }}
            >
              Yangilash
            </div>
          ) : (
            ""
          )}
        </div>
      )
    }
  ];

  const ordersList = useSelector((state) => state.orders.list);
  const { meta } = useSelector((state) => state.orders);

  useEffect(() => {
    if (meta.current_page) {
      window.scrollTo(0, 0);
    }
  }, [meta.current_page]);

  return (
    <MContainer>
      <div className="statistic_table_button_group">
        <div>
          <BsChevronDoubleLeft style={{ color: "blue" }} />
        </div>
        <div className="statistic_table_button_group_child">
          <div
            className={`statistic_table_button ${
              active_class === "all" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "all"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("all");
            }}
          >
            Barchasi
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "new" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "new"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("new");
            }}
          >
            Yangi
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "accepted" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "accepted"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("accepted");
            }}
          >
            Dostavkaga tayyor
          </div>

          <div
            className={`statistic_table_button ${
              active_class === "sent" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "sent"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("sent");
            }}
          >
            Yetkazilmoqda
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "delivered" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "delivered"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("delivered");
            }}
          >
            Yetkazildi
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "pending" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "pending"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("pending");
            }}
          >
            Qayta qo'ngiroq
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "cancelled" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "cancelled"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("cancelled");
            }}
          >
            Qaytib kildi
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "temporary" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "temporary"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("temporary");
            }}
          >
            Taqsimlanganlar
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "archieved" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "archieved"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("archieved");
            }}
          >
            Arxiv
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "spam" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "spam"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("spam");
            }}
          >
            Chorni spiska
          </div>
          <div
            className={`statistic_table_button ${
              active_class === "hold" ? "active_button" : ""
            }`}
            onClick={() => {
              let newFilter = {
                ...filter,
                page: 1,
                status: "hold"
              };
              setFilter(newFilter);
              dispatch(fetchAgentOrders(newFilter));
              set_active_class("hold");
            }}
          >
            Mahsulot kutilmoqda
          </div>
        </div>
        <div>
          <BsChevronDoubleRight style={{ color: "blue" }} />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={ordersList}
        paginationTotalRows={meta.total}
        noHeader
        defaultSortAsc={false}
        pagination
        highlightOnHover
        paginationServer
        onChangePage={(page) => {
          let newFilter = {
            ...filter,
            page: page
          };
          setFilter(newFilter);
          dispatch(fetchAgentOrders(newFilter));
        }}
        onChangeRowsPerPage={(per_page) => {
          let newFilter = {
            ...filter,
            per_page: per_page
          };
          setFilter(newFilter);
          dispatch(fetchAgentOrders(newFilter));
        }}
      />
    </MContainer>
  );
};

export default StatisticsTable;
