import React, { useEffect } from "react";
import { MContainer } from "../../element/Elemens";
import "../../assets/scss/_myteam.scss";
import { useDispatch, useSelector } from "react-redux";
import { RiFileCopyFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { fetchMyTeam } from "../../redux/actions/userActions";

const MyTeam = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyTeam());
  }, []);

  const userInfo = useSelector((state) => state.user.userInfo);
  const myTeamList = useSelector((state) => state.user.list);
  return (
    <MContainer>
      <div className="my__team__page">
        <div
          className="my__team__link"
          onClick={() => {
            navigator.clipboard.writeText(
              `https://uztez.uz/login?referral=${userInfo.id}`
            );
            toast("Linkdan nusxa olindi!");
          }}
        >
          <div>https://uztez.uz/login?referral={userInfo.id}</div>
          <div>
            <RiFileCopyFill />
          </div>
        </div>
        <div style={{ marginTop: 15, fontWeight: "bold" }}>
          "Mening jamoam" — bu "Uztez" hamkorlik dasturi ichidagi ichki
          hamkorlik dasturi. Quyida ko'rsatilgan havola orqali ro'yxatdan o'tgan
          foydalanuvchi (bundan buyon sizning jamoaga qo'shiladi). Sizning
          jamoangiz orasida "Admin" topgan mag'lag'ining 12% miqdorida
          "Uztez.uz" sizga bonus to'lov tariqasida darxol ajratib beradi.
        </div>

        {myTeamList && myTeamList.length >= 1 ? (
          <div className="my_team_user_grid">
            {myTeamList.map((item, index) => (
              <div key={index} className="my_team_user_card">
                <div># {index + 1}</div>
                <div>
                  Ismi: {item.first_name},{item.last_name}
                </div>
                <div>Mahsulot sotgan: {item.total_orders} ta</div>
                <div>Foyda: {item.team_member_balans} so'm</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="my__team__user__not">
            <h1>Jamoa azo'lari topilmadi</h1>
          </div>
        )}
      </div>
    </MContainer>
  );
};

export default MyTeam;
