import React from "react";
import { MContainer } from "../../element/Elemens";
import { useSelector } from "react-redux";
import ProfileEditComponent from "../../component/ProfileEditComponent";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../redux/actions/userActions";
import "../../assets/scss/_profile.scss";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.list);
  const user_info = useSelector((state) => state.user.userInfo);
  const { loading } = useSelector((state) => state.user);

  return (
    <MContainer>
      <div className="profile__edit__page">
        {user_info.id && (
          <ProfileEditComponent
            loading={loading}
            user_info={user_info}
            locations={locations}
            onClickUpdateProfile={(updateProfileData) => {
              dispatch(updateProfile(updateProfileData));
            }}
          />
        )}
      </div>
    </MContainer>
  );
};

export default ProfileEdit;
