import React, { useEffect } from "react";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { BiRefresh } from "react-icons/bi";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgentOrders,
  updateOrderNew
} from "../../redux/actions/orderActions";
import { OrderStatusLoading } from "../../component/loading/orderModal/orderModalLoading";
import ResponsivePagination from "react-responsive-pagination";
import moment from "moment";
import "moment/locale/uz-latn.js";
moment.locale("uz-latn");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const UserRequestModal = ({ show_modal, onCloseModal, order_status }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAgentOrders({ status: order_status }));
  }, []);

  const ordersList = useSelector((state) => state.orders.list);
  const { loading, meta } = useSelector((state) => state.orders);

  return (
    <Modal
      isOpen={show_modal}
      onRequestClose={onCloseModal}
      style={customStyles}
      contentLabel="Example Modal"
      appElement={document.getElementById("root")}
    >
      <div className="modal__title">
        <h3>So'rovlar</h3>
        <div>
          <GrClose style={{ cursor: "pointer" }} onClick={onCloseModal} />
        </div>
      </div>

      {loading ? (
        <div className="modal__content__grid__loading">
          {[1, 2, 3, 4, 5, 6, 7].map((item) => {
            return <OrderStatusLoading key={item} />;
          })}
        </div>
      ) : (
        ""
      )}

      {ordersList.length < 1 ? (
        <div className="modal__content__null">"Sizda buyurtmalar yoq"</div>
      ) : (
        <div className="modal__content">
          <div className="modal__content__grid">
            {ordersList.map((order, index) => (
              <div className="modal__content__cart" key={index}>
                <div className="modal__content__cart__header">
                  {order.product?.title}{" "}
                  <CurrencyFormat
                    value={order.product?.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />{" "}
                  so'm
                </div>
                <div className="modal__content__cart__content">
                  <div className="modal__content__content__flex order_id_style">
                    <div>#ID:</div>
                    <div>#{order.order_id}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Ismi:</div>
                    <div>{order.client?.full_name}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Raqami:</div>
                    <div>+{order.client?.phone_number}</div>
                  </div>
                  <div className="modal__content__content__flex order_date_now">
                    <div>Sana:</div>
                    <div>{moment(order.updated_at).fromNow(true)}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Viloyati:</div>
                    <div>{order.region?.title}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Izoh:</div>
                    <div>{order.comment}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Haydovchi izohi:</div>
                    <div>{order.courier_comment}</div>
                  </div>
                  <div className="modal__content__content__flex">
                    <div>Operator:</div>
                    <div className="order_operator_style">
                      {order.operator?.name}
                    </div>
                  </div>
                  <div className="modal__content__content__flex stream_price_style">
                    <div>Oqim narxi:</div>
                    <div>
                      <CurrencyFormat
                        value={order.stream_price || 0}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      so'm
                    </div>
                  </div>
                  {order_status === "pending" ? (
                    <div
                      className="modal__content__content__button"
                      onClick={() => {
                        dispatch(updateOrderNew(order.id));
                      }}
                    >
                      Yangilash <BiRefresh />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <ResponsivePagination
        current={meta.current_page}
        total={meta.last_page}
        onPageChange={(e) =>
          dispatch(
            fetchAgentOrders({ status: order_status, page: e, per_page: 10 })
          )
        }
      />
    </Modal>
  );
};

export default UserRequestModal;
