const INITIAL_STATE = {
  list_view: [],
  list_selling: [],
  loading: false,
  message: null,
  sidebarShow: "responsive",
  loadingOrder: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    /* other */
    case "fetch_products_wiew_start":
      return { ...state, loading: true, message: "" };
    case "fetch_products_wiew_error":
      return { ...state, message: payload, loading: false };
    case "fetch_products_wiew_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list_view: payload,
      };

    /* other */
    case "fetch_products_best_selling_start":
      return { ...state, loading: true, message: "" };
    case "fetch_products_best_selling_error":
      return { ...state, message: payload, loading: false };
    case "fetch_products_best_selling_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list_selling: payload,
      };

    /* other */
    default:
      return state;
  }
};
